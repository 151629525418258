export default function reducer(state, action) {
  switch (action.type) {
    case 'SET_STATE': {
      return {
        ...state,
        ...action.newValue
      };
    }
    default: {
      throw Error(`Unknown action: ${  action.type}`);
    }
  }
}
