import React from 'react';
import PropTypes from 'prop-types';
import MutedTextField from '../inputs/MutedTextField';
import InfoContainer from '../containers/infoContainer/InfoContainer';
import { BookingSummaryContainer } from './BookingSummary.styles';

const BookingSummary = (props) => {
  const { booking } = props;
  const { bookingNumber, confirmationNumber, customerName } = booking;
  const isFrench  = localStorage.getItem('ljs-lang') === 'fr-CA';
  const guestCompanyLabel = isFrench ? 'Entreprise invitée' : 'Guest Company';

  return (
    <InfoContainer title="Booking Summary">
      <BookingSummaryContainer>
        <MutedTextField name="bookingNumber" label="Booking Number" value={bookingNumber} />
        <MutedTextField name="confirmationNumber" label="Confirmation Number" value={confirmationNumber || 'N/A'} />
        <MutedTextField name="bookingFacilitator" label="Booking Facilitator" value="LodgeLink" />
        <span className="notranslate"><MutedTextField name="guestCompany" label={guestCompanyLabel} value={customerName || 'N/A'} /></span>
      </BookingSummaryContainer>
    </InfoContainer>
  );
};

BookingSummary.propTypes = {
  booking: PropTypes.shape({
    bookingNumber: PropTypes.string.isRequired,
    confirmationNumber: PropTypes.string.isRequired,
    customerName: PropTypes.string.isRequired
  })
};

BookingSummary.defaultProps = {
  booking: {}
};

export default BookingSummary;
