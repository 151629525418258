import { styled as styledMUI } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { colors } from '../../layout/css';

export const StyledButton = styledMUI(Button)((props) => ({
  color: props.primary ? colors.colorWhite : colors.admin.highlightTheme,
  backgroundColor: props.primary ? colors.admin.highlightTheme : colors.colorGrey04,
  '&:hover': {
    backgroundColor: props.primary ? colors.admin.lightTheme : colors.colorGrey05
  },
  padding: 10,
  fontWeight: 'strong'
}));
