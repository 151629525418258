import React, { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { Link, Typography } from '@mui/material';
import {
  LayoutAuthedBodyStyle,
  LayoutAuthedRootStyle,
  LayoutBodyContainerStyle,
  LayoutBodyStyle,
  LayoutRootStyle,
  FrontDeskBellStyle,
  SideImageStyle,
  LayoutAuthedBodyContainerStyle,
  LogoContainerStyle,
  WelcomeContinerStyle
} from './Layout.styles';
import { colorBlack, logoColor1, logoColor2 } from './css/colors';
import { Header } from './header/Header';
import { Footer } from './footer/Footer';
import SecurityNudge from '../buildingBlocks/securityNudgeModal/SecurityNudgeModal';
import LodgeLinkLogoColor from './logo/LodgeLinkLogoColor';
import NavBar from './navBar/NavBar';
import { useUserToken } from '../hooks/useUserToken';

const Layout = (props) => {
  const { component, authed, isNotFound } = props;
  const propertyName = localStorage.getItem('propertyName');
  
  const navigate = useNavigate();
  const { resetUserToken } = useUserToken();

  const logout = () => {
    resetUserToken();
    navigate('/login');
  };

  const redirectToDashboard = () => (isNotFound ? navigate('/dashboard') : null);
  const [securityNudgeModalOpen, setSecurityNudgeModalOpen] = useState(false);
  const handleClose = () => setSecurityNudgeModalOpen(false);
  const handleIdle = () => {
    setSecurityNudgeModalOpen(true);
  };
  const handleActive = () => {
    if (!securityNudgeModalOpen) setSecurityNudgeModalOpen(false);
  };
  useIdleTimer({
    timeout: 30 * 60 * 1000, // 30 minutes of idle time
    onIdle: handleIdle,
    onActive: handleActive,
    debounce: 500,
    events: ['mousemove', 'keydown', 'scroll', 'click']
  });

  return (
    <>
      <CssBaseline />
      {authed ? (
        <Box sx={LayoutAuthedRootStyle}>
          <Header logout={logout} propertyName={propertyName} />
          <Box sx={LayoutAuthedBodyContainerStyle}>
            <NavBar redirectToDashboard={redirectToDashboard} />
            <Box sx={LayoutAuthedBodyStyle}>{component}</Box>
          </Box>
          <Footer />
          {securityNudgeModalOpen && (
            <SecurityNudge securityNudgeModalOpen={securityNudgeModalOpen} handleClose={handleClose} logout={logout} />
          )}
        </Box>
      ) : (
        <Box sx={LayoutRootStyle}>
          <Link href="https://www.lodgelink.com/partnerhub-support/" target="_blank">
            <Box component="img" sx={SideImageStyle} src="/Side-image.jpg" />
          </Link>
          <Box sx={LayoutBodyStyle}>
            <Box sx={LogoContainerStyle}>
              <LodgeLinkLogoColor width="210px" height="70px" />
            </Box>
            <Box sx={WelcomeContinerStyle}>
              <Typography color={colorBlack} fontSize="40px" fontWeight="bold">
                Welcome to
              </Typography>
              <Typography color={logoColor1} fontSize="40px" fontWeight="bold" marginLeft="7px">
                <span className="notranslate">Partner</span>
              </Typography>
              <Typography color={logoColor2} fontSize="40px" fontWeight="bold">
                <span className="notranslate">Hub</span>
              </Typography>
            </Box>
            <Box component="img" sx={FrontDeskBellStyle} src="/Front-Desk-Bell.png" />
            <Box sx={LayoutBodyContainerStyle}>{component}</Box>
          </Box>
        </Box>
      )}
    </>
  );
};

Layout.propTypes = {
  authed: PropTypes.bool,
  isNotFound: PropTypes.bool,
  component: PropTypes.shape({})
};

Layout.defaultProps = {
  authed: false,
  component: null,
  isNotFound: false
};

export default Layout;
