import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useFormik } from 'formik';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DateTime } from 'luxon';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DownloadCsvButton } from '../../downloadCsvButton/DownloadCsvButton';
import { colorBurgundy, colorWhite } from '../../../layout/css/colors';
import {
  ControlBoxStyle,
  InputBoxStyle,
  SearchBodyStyle,
  SearchButtonsContainerStyle,
  SearchHeaderStyle,
  StyledButton,
  StyledH1
} from './AdvancedSearch.styles';
import Transition from '../../../layout/animation/Transition';

const AdvancedSearchCrewRegistration = (props) => {
  const { searchInput, handleSetState, pagination, tableData } = props;
  const CSV_DEFAULT_HEADER = [
    { key: 'fullName', value: 'Full Name' },
    { key: 'enteredName', value: 'Entered Name' },
    { key: 'propertyName', value: 'Property Name' },
    { key: 'pinNumber', value: 'Pin Number' },
    { key: 'roomNumber', value: 'Room Number' },
    { key: 'dateTime', value: 'Date' },
    { key: 'actionType', value: 'Action' }
  ];
  const returnSearchInput = (values) => {
    const startDate = values.startDate ? values.startDate.format('YYYY-MM-DD') : '';
    const endDate = values.endDate ? values.endDate.format('YYYY-MM-DD') : '';
    const searchText = values.searchText ? values.searchText : '';
    return {
      endDate,
      startDate,
      searchText
    };
  };
  const formik = useFormik({
    initialValues: {
      startDate: null,
      endDate: null,
      searchText: null
    },
    onSubmit: (values) => {
      handleSetState({
        pagination: {
          ...pagination,
          page: 0
        },
        loading: true,
        searchSubmit: true,
        searchInput: {
          ...searchInput,
          ...returnSearchInput(values),
          skip: 0
        }
      });
    },
    onReset: () => {
      handleSetState({
        loading: true,
        searchSubmit: true,
        searchInput: {
          searchText: null,
          startDate: DateTime.now().toUTC().minus({ days: 1 }).toFormat('yyyy-MM-dd'),
          endDate: DateTime.now().toUTC().toFormat('yyyy-MM-dd'),
          top: 9,
          skip: 0
        }
      });
    }
  });

  return (
    <Transition>
      <Box bgcolor={colorWhite} margin="10px" marginTop="50px">
        <Box sx={SearchHeaderStyle} height="40px" bgcolor={colorBurgundy}>
          <StyledH1 color={colorWhite} size="16px">
            Search
          </StyledH1>
        </Box>
        <Box component="form" noValidate autoComplete="off" bgcolor={colorWhite} sx={SearchBodyStyle}>
          <Box sx={InputBoxStyle}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date"
                format="YYYY-MM-DD"
                value={formik.values.startDate}
                onChange={(date) => formik.setFieldValue('startDate', date)}
              />
              <DatePicker
                label="End Date"
                format="YYYY-MM-DD"
                value={formik.values.endDate}
                onChange={(date) => formik.setFieldValue('endDate', date)}
              />
              <TextField
                key="searchText"
                fullWidth
                value={formik.values.searchText || ''}
                onChange={(e) => formik.setFieldValue('searchText', e.target.value)}
                name="searchText"
                label="Search by entered name, pin number or room number"
                variant="outlined"
              />
            </LocalizationProvider>
          </Box>
          <Box sx={ControlBoxStyle}>
            <Box sx={SearchButtonsContainerStyle}>
              <DownloadCsvButton
                tableData={tableData}
                fileTitle="Sign_in_Sheet"
                columns={CSV_DEFAULT_HEADER}
                startDate={searchInput.startDate}
                endDate={searchInput.endDate}
              />
              <StyledButton
                disabled={!formik.values.endDate && !formik.values.startDate}
                name="clearSearch"
                onClick={() => {
                  formik.handleReset();
                  formik.resetForm();
                }}
              >
                Clear Search
              </StyledButton>
              <StyledButton
                disabled={!formik.values.endDate || !formik.values.startDate}
                name="submitSearch"
                onClick={() => formik.handleSubmit()}
                primary="true"
              >
                Submit
              </StyledButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Transition>
  );
};

AdvancedSearchCrewRegistration.propTypes = {
  searchInput: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    searchText: PropTypes.string
  }),
  tableData: PropTypes.shape([{}]),
  handleSetState: PropTypes.func,
  pagination: PropTypes.shape({ rowsPerPage: PropTypes.number, page: PropTypes.number })
};

AdvancedSearchCrewRegistration.defaultProps = {
  searchInput: {
    startDate: undefined,
    endDate: undefined,
    searchText: undefined
  },
  tableData: [],
  handleSetState: () => {},
  pagination: { page: 0, rowsPerPage: 9 }
};

export default AdvancedSearchCrewRegistration;
