import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import App from './App';
import { CrewRegistrationApolloClientContext, crewRegistration } from './context/crewRegistrationApolloClient';
import reportWebVitals from './reportWebVitals';

const client = new ApolloClient({
  uri: process.env.REACT_APP_LODGELINK_API_URI,
  cache: new InMemoryCache()
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ApolloProvider client={client}>
    <CrewRegistrationApolloClientContext.Provider value={crewRegistration}>
      <App />
    </CrewRegistrationApolloClientContext.Provider>
  </ApolloProvider>
);
reportWebVitals();
