import { useUserTokenContext, useUserTokenDispatchContext } from '../context/userToken.context';
import { ActionType } from '../lib/enum/index';

export function useUserToken() {
  const userToken = useUserTokenContext();
  const dispatch = useUserTokenDispatchContext();

  const resetUserToken = () => {
    dispatch({
      type: ActionType.RESET_TOKEN
    });
  };

  return {
    token: userToken,
    resetUserToken
  };
}