import styled from 'styled-components';
import { colorGrey02, logoColor3withOpacity } from '../../../layout/css/colors';

export const StyledRoomCard = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 2px;
  min-width: 12.3%;
  padding: 10px 0 10px;
  &:nth-child(8) {
    flex-grow: 1;
  }
  &:last-child {
    flex-grow: 1;
  }


`;

export const RoomTableStyle = {
  display: 'flex',
  justifyContent: 'start',
  flexWrap: 'wrap'
};

export const StyledRoomCardTitle = styled.div`
  padding: 0 0 10px 5px;
  margin: 1px;
  margin-left: 1rem;
  font-weight: bold;
  color: ${colorGrey02}
`;

export const SearchedGuestStyle = {
  backgroundColor: logoColor3withOpacity(0.5),
  padding: '5px',
  marginRight: '20px',
  maxWidth: '30ch',
  borderRadius: '5px'
};
