import { styled as styledMUI } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { colors } from '../../layout/css';

const fontFamily = 'Avenir Next';

export const StyledButton = styledMUI(Button)((props) => ({
  color: props.primary ? colors.colorBlue : colors.colorWhite,
  backgroundColor: props.primary ? colors.colorWhite : colors.colorBlue,
  '&:hover': {
    backgroundColor: props.primary ? colors.colorGrey05 : colors.logoColor3,
    opacity: 0.5
  },
  transition: 'background-color 0.5s ease',
  padding: 10,
  borderRadius: '8px',
  border: `1px solid ${colors.colorBlue}`
}));

export const ButtonTypography = styledMUI(Typography)({
  fontFamily,
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '16px',
  letterSpacing: '0.5px',
  textAlign: 'center'
});

export const BodyTypography = styledMUI(Typography)({
  fontFamily,
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.5px',
  textAlign: 'left'
});

export const HeaderTypography = styledMUI(Typography)({
  fontFamily,
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px'
});

export const ModalStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '16px',
  width: '480px',
  minHeight: '220px',
  background: colors.colorWhite,
  borderRadius: '4px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  outline: 'none'
};

export const ModalIconStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '18px',
  height: '18px',
  background: colors.iconYellow,
  borderRadius: '50%',
  fontWeight: 'bold',
  fontSize: '14px',
  position: 'relative'
};

export const ModalHeaderStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '8px 0 0 10px',
  gap: '8px',
  width: '448px',
  height: '24px',
  flex: 'none',
  order: 0,
  alignSelf: 'stretch',
  flexGrow: 0
};

export const ModalBodyStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  padding: '12px 0 0 0'
};

export const ModalFooterContentStyle = {
  flexDirection: 'row',
  alignItems: 'center',
  height: '38px',
  backgroundColor: colors.colorWhite,
  order: 1,
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '16px',
  width: '100%'
};
