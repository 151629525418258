import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { LoadingPageStyle } from './Loading.styles';

const Loading = () => (
    <Box sx={LoadingPageStyle}>
      <CircularProgress />
    </Box>
  )

export default Loading;
