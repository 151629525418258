/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@mui/material';
import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import { get } from 'lodash';
import InfoContainer from '../containers/infoContainer/InfoContainer';
import { colorGrey04 } from '../../layout/css/colors';
import { QUERY_NOTICES_AND_INSTRUCTIONS } from './NoticesAndInstructions.gql';
import { useUserToken } from '../../hooks/useUserToken';

const NoticesAndInstructions = (props) => {
  const { bookingNumber } = props;
  const { token: userToken } = useUserToken();
  const { data, loading } = useQuery(QUERY_NOTICES_AND_INSTRUCTIONS, {
    variables: { bookingNumber, token: userToken.token },
    fetchPolicy: 'cache-and-network'
  });
  const notices = get(data, 'PartnerHubQueries.bookingNoticesAndInstructions.notices', []);
  const instructions = get(data, 'PartnerHubQueries.bookingNoticesAndInstructions.instructions', []);

  return (
    <InfoContainer title="Notices and Instructions">
      <Box padding="1rem">
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Box marginBottom="1rem">
              <Box borderBottom={1} borderColor={colorGrey04} marginBottom="1rem">
                <Typography variant="h7" fontWeight="bold">
                  Standard Instructions
                </Typography>
              </Box>
              <Box>
                {!notices.length ? (
                  <Typography variant="p">There are no standard instructions on this booking</Typography>
                ) : (
                  <ul>
                    {notices.map((notice, index) => (
                      <li key={`notice-${index}`}>
                        <Typography key={`notice-${index}`} variant="p">
                          {notice}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                )}
              </Box>
            </Box>
            {/* Instructions */}
            <Box marginBottom="1rem">
              <Box borderBottom={1} borderColor={colorGrey04} marginBottom="1rem">
                <Typography variant="h7" fontWeight="bold">
                  Special Booking Instructions
                </Typography>
              </Box>
              <Box>
                {!instructions.length ? (
                  <Typography variant="p">There are no special instructions on this booking</Typography>
                ) : (
                  <ul>
                    {instructions.map((notice, index) => (
                      <li key={`request-${index}`}>
                        <Typography key={`request-${index}`} variant="p">
                          {notice}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                )}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </InfoContainer>
  );
};

NoticesAndInstructions.propTypes = {
  bookingNumber: PropTypes.string.isRequired
};

export default NoticesAndInstructions;
