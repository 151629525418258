import styled from 'styled-components';
import { colorGrey04 } from '../../../layout/css/colors';

export const HeaderContainerStyle = {
  '& > :not(style)': { p: 1 }
};

export const StyledH1 = styled.h1`
  color: ${(props) => props.color};
  font-size: ${(props) => props.size};
  font-weight: bold;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid ${colorGrey04};
`;
