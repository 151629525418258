import styled from 'styled-components';
import { colors } from '../css';

export const LayoutAuthedHeaderStyle = {
  position: 'fixed',
  left: '0px',
  top: '0px',
  bgcolor: colors.colorWhite,
  height: '100px',
  width: '100vw',
  display: 'flex',
  flexDirection: 'row',
justifyContent: 'space-between',
  zIndex: 1600
};

export const PartnerHubContainerStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  margin: '10px',
  marginTop: '32px',

};

export const StyledH2 = styled.h2`
  background: white;
  color: ${colors.colorGrey03};
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  padding: 0;
`;

export const LogoContainerStyle = {
  margin: '25px',
  marginTop: '20px',
  marginLeft: '110px'
};

export const StyledH1 = styled.h1`
  background: white;
  color: ${(props) => props.color};
  font-size: ${(props) => (props.authed ? '25px' : '40px')};
  font-weight: bold;
  margin: 0;
  padding: 0;
`;

export const ControlContainerStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
  margin: '32px',
  marginRight: '40px',

};

export const ControlBoxStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'flex-end',
  width: '100%'
};
