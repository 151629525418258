import React, { createContext, useContext, useEffect, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import timerReducer from '../reducer/timer.reducer';

const initialState = {
  timersMap: new Map(),
  timerFlag: false
};

const TimerContext = createContext();
const TimerDispatcherContext = createContext();

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return undefined;
  }, [delay]);
}

export const TimerProvider = ({ children }) => {
  const [timer, timerDispatch] = useReducer(timerReducer, initialState);
  const { timersMap } = timer;
  useInterval(() => {
    timersMap.forEach((value, key) => {
      if (value > 0) {
        timerDispatch({
          type: 'TIMER_TICK',
          key,
          value
        });
      }
    });
  }, 1000);

  return (
    <TimerContext.Provider value={timer}>
      <TimerDispatcherContext.Provider value={timerDispatch}>{children}</TimerDispatcherContext.Provider>
    </TimerContext.Provider>
  );
};

export function useTimerContext() {
  return useContext(TimerContext);
}

export function useTimerDispatchContext() {
  return useContext(TimerDispatcherContext);
}

TimerProvider.propTypes = {
  children: PropTypes.shape({})
};

TimerProvider.defaultProps = {
  children: null
};
