import React from 'react';
import PropTypes from 'prop-types';
import { Box, List, ListItemButton, ListItemIcon, Tooltip } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import GroupIcon from '@mui/icons-material/Group';
import { LayoutSideBarStyle, ListItemButtonStyle, ListItemIconStyle } from './NavBar.styles';
import { useAppState } from '../../hooks/useAppState';
import { VIEWS } from '../../buildingBlocks/enum';

const NavBar = (props) => {
  const { redirectToDashboard } = props;
  const { setAppState, appState } = useAppState();
  const { currentView } = appState ?? {};

  return (
    <Box sx={LayoutSideBarStyle}>
      <List component="nav" aria-label="main folders" sx={{ marginTop: '100px' }}>
        <Tooltip title="Dashboard" placement="right">
          <ListItemButton
            selected={currentView === VIEWS.default}
            onClick={() => {
              redirectToDashboard(); // Only navigate in case we are on a different page. ex: Not Found
              setAppState({ currentView: VIEWS.default });
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            sx={ListItemButtonStyle}
          >
            <ListItemIcon sx={ListItemIconStyle}>
              <HomeIcon sx={{ color: 'white', margin: '5px', fontSize: '30px' }} />
            </ListItemIcon>
          </ListItemButton>
        </Tooltip>
        <Tooltip title="Select a booking from the table to view the details" placement="right">
          <span>
            <ListItemButton selected={currentView === VIEWS.bookingDetails} sx={ListItemButtonStyle} disabled>
              <ListItemIcon sx={ListItemIconStyle}>
                <CalendarMonthIcon sx={{ color: 'white', margin: '5px', fontSize: '30px' }} />
              </ListItemIcon>
            </ListItemButton>
          </span>
        </Tooltip>
        <Tooltip title="Sign-In Sheet" placement="right">
          <span>
            <ListItemButton
              selected={currentView === VIEWS.crewRegistration}
              sx={ListItemButtonStyle}
              onClick={() => {
                redirectToDashboard(); // Only navigate in case we are on a different page. ex: Not Found
                setAppState({ currentView: VIEWS.crewRegistration });
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            >
              <ListItemIcon sx={ListItemIconStyle}>
                <GroupIcon sx={{ color: 'white', margin: '5px', fontSize: '30px' }} />
              </ListItemIcon>
            </ListItemButton>
          </span>
        </Tooltip>
      </List>
    </Box>
  );
};

NavBar.propTypes = {
  redirectToDashboard: PropTypes.func
};

NavBar.defaultProps = {
  redirectToDashboard: () => {}
};

export default NavBar;
