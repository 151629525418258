import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { HeaderContainerStyle, StyledH1 } from './InfoContainer.styles';
import { colorGrey02, colorGrey04, colorGrey05, colorWhite } from '../../../layout/css/colors';

const InfoContainer = (props) => {
  const { children, title } = props;

  return (
    <Box bgcolor={colorWhite} marginBottom="28px" border={1} borderColor={colorGrey04}>
      <Box bgcolor={colorGrey05} height="40px" sx={HeaderContainerStyle}>
        <StyledH1 color={colorGrey02} size="16px">
          {title}
        </StyledH1>
      </Box>
      {children}
    </Box>
  );
};

InfoContainer.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired
};

export default InfoContainer;
