import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';
import { colorGrey04 } from '../../../../layout/css/colors';
import { GuestScheduleItemStyle, RoomInfoHeaderStyle, RoomScheduleCardStyle } from './RoomScheduleCard.style';

const GuestScheduleItem = (props) => {
  const { guest } = props;
  const { id: guestId, guestName, guestDaysByRange } = guest ?? {};

  return (
    <Box key={guestId} sx={GuestScheduleItemStyle}>
      <Typography fontWeight="bold" fontSize="11px">
        <span className="notranslate">{guestName}</span>
      </Typography>
      <Stack direction="column" spacing={1}>
        {guestDaysByRange.map((guestDay) => (
          <Typography key={guestDay} fontSize="10px">
            {guestDay}
          </Typography>
        ))}
      </Stack>
    </Box>
  );
};

const RoomScheduleCard = (props) => {
  const { room, roomNumber } = props;
  const { numberOfNights, roomName, totalInboundRoomPrice, guestSchedule } = room ?? {};
  const { formatted: formattedInboundRoomPrice } = totalInboundRoomPrice ?? {};

  return (
    <Box sx={RoomScheduleCardStyle} border={1} borderColor={colorGrey04}>
      <Stack direction="row" spacing={10} sx={RoomInfoHeaderStyle} borderBottom={1} borderColor={colorGrey04}>
        <Typography fontWeight="bold" fontSize="14px">{`Room ${roomNumber}`}</Typography>
        <Typography fontWeight="bold" fontSize="14px">
          <span className="notranslate">{roomName}</span>
        </Typography>
        <Typography fontWeight="bold" fontSize="14px">{`Room Night: ${numberOfNights}`}</Typography>
        <Typography fontWeight="bold" fontSize="14px">{`Room Total: ${formattedInboundRoomPrice}`}</Typography>
      </Stack>
      <Stack direction="row" spacing={2} margin={1}>
        {guestSchedule.map((guest) => {
          const { id: guestId } = guest;
          return <GuestScheduleItem key={guestId} guest={guest} />;
        })}
      </Stack>
    </Box>
  );
};

GuestScheduleItem.propTypes = {
  guest: PropTypes.shape({
    id: PropTypes.number,
    guestName: PropTypes.string,
    guestDaysByRange: PropTypes.arrayOf(PropTypes.string)
  })
};

GuestScheduleItem.defaultProps = {
  guest: {}
};

RoomScheduleCard.propTypes = {
  room: PropTypes.shape({
    roomIndex: PropTypes.number,
    numberOfNights: PropTypes.number,
    roomName: PropTypes.string,
    totalRoomPrice: PropTypes.shape({
      formatted: PropTypes.string
    }),
    guestSchedule: PropTypes.arrayOf(GuestScheduleItem.propTypes.guest)
  }),
  roomNumber: PropTypes.number
};

RoomScheduleCard.defaultProps = {
  room: {},
  roomNumber: 0
};

export default RoomScheduleCard;
