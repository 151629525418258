export default function timerReducer(state, action) {
    switch (action.type) {
      case 'RESET_TIMER': {
        return {
          ...state,
          timersMap: new Map(state.timersMap.set(action.bookingNumber, process.env.REACT_APP_TIMER_VALUE))
        };
      }
      case 'TIMER_TICK': {
        const { key, value } = action;
        const { timersMap } = state;
        return {
          ...state,
          timersMap: new Map(timersMap.set(key, value - 1))
        };
      }
      default: {
        throw Error(`Unknown action: ${  action.type}`);
      }
    }
  }
  