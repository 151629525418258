import { useTimerContext, useTimerDispatchContext } from '../context/timer.context';

export function useTimer() {
  const timers = useTimerContext();
  const timerDispatch = useTimerDispatchContext();
  const resetBookingTimer = (bookingNumber) => {
    timerDispatch({
      type: 'RESET_TIMER',
      bookingNumber
    });
  };

  return {
    timers,
    resetBookingTimer
  };
}
