import { ActionType } from '../lib/enum/index';

export default function reducer(state, action) {
  switch (action.type) {
    case ActionType.SET_TOKEN: {
      return {
        ...state,
        token: action.newToken
      };
    }
    case ActionType.RESET_TOKEN: {
      return {
        ...state,
        token: null
      }
    }
    default: {
      throw Error(`Unknown action: ${  action.type}`);
    }
  }
}
