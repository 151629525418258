import { useAppStateContext, useStateDispatchContext } from '../context/state.context';

export function useAppState() {
  const appState = useAppStateContext();
  const stateDispatch = useStateDispatchContext();

  const setAppState = (newValue) => {
    stateDispatch({
      type: 'SET_STATE',
      newValue
    });
  };

  return {
    appState,
    setAppState
  };
}
