import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import reducer from '../reducer/state.reducer';
import { VIEWS } from '../buildingBlocks/enum';

const initialState = {
  currentView: VIEWS.default,
  bookingDetailsOpen: null,
  otpDetailsMap: new Map()
};

const StateContext = createContext();
const StateDispatcherContext = createContext();

export const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StateContext.Provider value={state}>
      <StateDispatcherContext.Provider value={dispatch}>{children}</StateDispatcherContext.Provider>
    </StateContext.Provider>
  );
}

export function useAppStateContext() {
  return useContext(StateContext);
}

export function useStateDispatchContext() {
  return useContext(StateDispatcherContext);
}

StateProvider.propTypes = {
  children: PropTypes.shape({})
};

StateProvider.defaultProps = {
  children: null
};
