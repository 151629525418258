import { styled as styledMUI } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { colorWhite, logoColor3 } from '../../layout/css/colors';

export const ModalStyle = {
  position: 'absolute',
  top: '50%',
  left: 'calc(50% - 200px)',
  transform: 'translate(-50%, -50%)',
  maxWidth: 800,
  height: 600,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 3,
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
};

export const PropertiesContainerStyle = {
  maxWidth: 750,
  height: 500,
  overflow: 'auto',
  p: 1,
  m: 1
};

export const StyledButton = styledMUI(Button)({
  color: colorWhite,
  backgroundColor: logoColor3,
  '&:hover': {
    backgroundColor: logoColor3
  },
  padding: 10,
  width: '50ch !important'
});
