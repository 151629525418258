import gql from 'graphql-tag';

export const INITIATE_PARTNER_AUTHENTICATION = gql`
  mutation MutationInitiatePartnerAuthentication($emailAddress: String!) {
    PartnerHubMutations {
      initiatePartnerAuthentication(emailAddress: $emailAddress) {
        __typename
        message
        properties {
          id
          location {
            __typename
            name
          }
          name
          address {
            __typename
            address
            postalCode
          }
          supplierName
        }
      }
    }
  }
`;
