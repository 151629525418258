import { styled as styledMUI } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { colors } from '../../layout/css';

export const StyledButton = styledMUI(Button)((props) => ({
  color: props.primary ? colors.colorWhite : colors.admin.highlightTheme,
  backgroundColor: props.primary ? colors.admin.highlightTheme : colors.colorGrey04,
  '&:hover': {
    backgroundColor: props.primary ? colors.admin.lightTheme : colors.colorGrey05
  },
  padding: 10,
  fontWeight: 'strong',
  width: '170px',
  margin: 5
}));

export const ModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  zIndex: 1000
};

export const TextComponentRowStyle = {
  display: 'flex',
  flexDirection: 'row'
};
