import React from 'react';
import PropTypes from 'prop-types';
import { ButtonState, StyledVCCButton } from './VCCButton.styles';
import withAnalytics from '../../hoc/withAnalytics';

const VCCButton = (props) => {
  const { bookingNumber, setState, otpDetailsMap, analytics, resetBookingTimer, hasVCC } = props;

  const bookingHasOTP = (bookingNo) => {
    const otpDetails = otpDetailsMap.get(bookingNo);
    const { otp, verificationCode, bookingNumber: otpBookingNumber, iv } = otpDetails ?? {};
    return !!otp && !!iv && !!verificationCode && !!otpBookingNumber;
  };

  const bookingHasIV = (bookingNo) => {
    const otpDetails = otpDetailsMap.get(bookingNo);
    const { iv, verificationCode } = otpDetails ?? {};
    return !!iv && !!verificationCode;
  };

  if (bookingHasOTP(bookingNumber))
    return (
      <StyledVCCButton
        name={`vccButtonWithOTP-${bookingNumber}`}
        state={ButtonState.accessed}
        onClick={() => {
          setState({ otpModalOpen: bookingNumber });
        }}
      >
        View VCC
      </StyledVCCButton>
    );

  if (bookingHasIV(bookingNumber))
    return (
      <StyledVCCButton
        name={`vccButtonWithIV-${bookingNumber}`}
        state={ButtonState.clicked}
        onClick={() => {
          setState({ otpModalOpen: bookingNumber });
          analytics.trackGA4('track-event', {
            eventName: 'clickEnterCode',
            sectionName: 'Bookings Table'
          });
        }}
      >
        Enter Code
      </StyledVCCButton>
    );

  return (
    <StyledVCCButton
      name={`vccButtonDefault-${bookingNumber}`}
      state={ButtonState.default}
      onClick={() => {
        setState({ otpModalOpen: bookingNumber });
        resetBookingTimer(bookingNumber);
        analytics.trackGA4('track-event', {
          eventName: 'clickAccessVCC',
          sectionName: 'Bookings Table',
          bookingNumber
        });
      }}
      disabled={!hasVCC}
    >
      Access VCC
    </StyledVCCButton>
  );
};

VCCButton.propTypes = {
  bookingNumber: PropTypes.string,
  setState: PropTypes.func,
  otpDetailsMap: PropTypes.instanceOf(Map),
  analytics: PropTypes.shape({
    trackGA4: PropTypes.func
  }),
  resetBookingTimer: PropTypes.func,
  hasVCC: PropTypes.bool
};

VCCButton.defaultProps = {
  bookingNumber: '',
  setState: () => {},
  otpDetailsMap: {},
  analytics: {},
  resetBookingTimer: () => {},
  hasVCC: false
};

export default withAnalytics()(VCCButton);
