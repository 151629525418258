import React from 'react';
import { VirtualCard } from '../../../layout/css/colors';
import { ReactComponent as Visa } from './img/vbm-2022.svg';
import { ReactComponent as VisaCA } from './img/vbm-com-2022.svg';
import { ReactComponent as MasterCard } from './img/mc-2022.svg';

export const VCCStyle = (isCA) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '86mm',
  height: '54mm',
  backgroundColor: isCA ? VirtualCard.cardBackgroundCA : VirtualCard.cardBackground,
  borderRadius: '3mm',
  border: isCA ? 'solid' : ''
});

export const TopBlockStyle = {
  marginTop: '0mm',
  marginLeft: '6mm',
  marginRight: '3mm',
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap'
};

export const MidBlockStyle = {
  marginLeft: '6mm',
  marginRight: '3mm',
  marginTop: '-1mm',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap'
};

export const BottomBlockStyle = {
  marginLeft: '6mm',
  marginRight: '3mm',
  display: 'flex',
  justifyContent: 'space-between'
};

export const VCCTextStyle = {
  colour: VirtualCard.textColour
};

export const VisaLogoStyle = {
  width: '19.335mm',
  height: '6.095mm'
};

export const VCCLogo = (isCSI, isCA) => {
  if (isCSI) return <MasterCard width="21.335mm" height="9.095mm" />;

  if (isCA) return <VisaCA width="19.335mm" height="8.095mm" />;
  return <Visa width="19.335mm" height="8.095mm" />;
};

export const VCCLogoStyle = (isCA) => {
  if (isCA) return { width: '18.0mm', height: '8.095mm', mb: '3mm' };
  return { width: '20.0mm', height: '8.095mm', mb: '3mm' };
};
