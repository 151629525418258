import styled from 'styled-components';

export const BookingDetailsHeaderStyle = {
  '& > :not(style)': { m: 1, p: 1, maxWidth: '30ch' },
  marginTop: 2,
  height: '3ch'
};

export const StyledH1 = styled.h1`
  color: ${(props) => props.color};
  font-size: ${(props) => props.size};
  font-weight: bold;
  margin: 10;
  padding: 10;
`;

export const BookingDetailsNavStyle = { display: 'flex', flexDirection: 'row', alignItems: 'center', height: '40px' };

export const BookingDetailsViewStyle = { margin: '10px', marginTop: '50px' };
