import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const Transition = (props) => {
  const { duration, delay, children, onComplete } = props;
  const [maxIsVisible, setMaxIsVisible] = useState(0);
  const transitionDuration = typeof duration === 'number' ? duration : 400;
  const transitionDelay = typeof delay === 'number' ? delay : 50;

  useEffect(() => {
    const count = React.Children.count(children);

    if (count === maxIsVisible) {
      const timeout = setTimeout(() => {
        if (onComplete) onComplete();
      }, transitionDuration);
      return () => clearTimeout(timeout);
    }

    const increment = count > maxIsVisible ? 1 : -1;
    const timeout = setTimeout(() => {
      setMaxIsVisible(maxIsVisible + increment);
    }, transitionDelay);
    return () => clearTimeout(timeout);
  }, [React.Children.count(children), transitionDelay, maxIsVisible, transitionDuration]);

  return (
    <Box>
      {React.Children.map(children, (child, i) => (
        <Box
          style={{
            transition: `opacity ${transitionDuration}ms, transform ${transitionDuration}ms`,
            transform: maxIsVisible > i ? 'none' : 'translateY(20px)',
            opacity: maxIsVisible > i ? 1 : 0
          }}
        >
          {child}
        </Box>
      ))}
    </Box>
  );
};

Transition.propTypes = {
  duration: PropTypes.number,
  delay: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onComplete: PropTypes.func
};

Transition.defaultProps = {
  duration: 400,
  delay: 50,
  children: null,
  onComplete: () => {}
};

export default Transition;
