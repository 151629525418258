import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserToken } from '../../hooks/useUserToken';

const Authenticate = () => {
  const { token: userToken } = useUserToken();
  const navigate = useNavigate();

  useEffect(() => {
    if (userToken) {
      navigate('/dashboard');
    } else {
      navigate('/login');
    }
  }, [userToken, navigate]);
}

export default Authenticate;
