import gql from 'graphql-tag';

export const QUERY_SEARCH_BOOKINGS = gql`
  query PartnerHubSearchBookings($token: String!, $searchInput: PartnerBookingSearchInput!) {
    PartnerHubQueries {
      partnerSearchBookings(token: $token, searchInput: $searchInput) {
        __typename
        bookingCount
        bookings {
          __typename
          id
          guests {
            __typename
            id
            firstName
            lastName
            roomIndex
          }
          confirmationNumber
          customerName
          bookingNumber
          checkin
          checkout
          createdAt
          hasVCC
          hasTimeZoneFlag
          propertyTimeZone
        }
      }
    }
  }
`;
