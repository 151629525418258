import React from 'react';
import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { CircularProgress, Typography } from '@mui/material';
import { ModalStyle, PropertiesContainerStyle, StyledButton } from './PropertySelectionModal.styles';
import { GENERATE_PARTNER_MAGIC_LINK } from './PropertySelectionModal.gql';

const renderButtonContent = ({ loading, isSent }) => {
  if (loading) return <CircularProgress />;
  if (isSent) return `Re-send authorization email`;
  return `Send authorization email`;
};

const PropertySelectionModal = (props) => {
  const { values, handleSetState } = props;
  const sortedProperties = values.properties.sort((a, b) => {
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;
    return 0;
  });
  const { supplierName } = values.properties[0];
  const [generatePartnerMagicLink, { data, error, loading }] = useMutation(GENERATE_PARTNER_MAGIC_LINK, {
    onCompleted: (onCompleData) => {
      handleSetState({
        multiPropertyGen: {
          error: false,
          loading: false,
          data: onCompleData
        }
      });
    },
    onError: (onCompleError) => {
      handleSetState({
        multiPropertyGen: {
          error: onCompleError,
          loading: false,
          data: undefined
        }
      });
    }
  });
  const formik = useFormik({
    initialValues: {
      propertyId: 0
    },
    onSubmit: async (formValues) => {
      try {
        const { propertyId } = formValues;
        const property = values.properties.find((prop) => prop.id === parseInt(propertyId, 10));
        const input = { propertyId: property.id, emailAddress: values.emailAddress };
        await generatePartnerMagicLink({
          variables: { input }
        });
        handleSetState({ propertySelectionValues: null });
      } catch (onSubmitError) {
        // Swallow the error
        handleSetState({ propertySelectionValues: null });
      }
    }
  });

  const handleToggle = (event) => {
    formik.setFieldValue('propertyId', event.target.value);
  };

  const isSent = error || data;
  return (
    <Modal open onClose={() => handleSetState({ propertySelectionValues: null })}>
      <Box sx={ModalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2" fontWeight="bold" marginBottom={1}>
          {`${supplierName} Properties`}
        </Typography>
        <Box sx={PropertiesContainerStyle}>
          <FormControl>
            <RadioGroup onChange={handleToggle} value={formik.values.propertyId}>
              {sortedProperties.map((property) => (
                <FormControlLabel
                  key={property.id}
                  value={property.id}
                  control={<Radio name={`radio-${property.id}`}/>}
                  label={`
                  ${property.name}, 
                  ${property.location.name}, 
                  ${property.address.address}, 
                  ${property.address.postalCode}`}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
        <StyledButton
          name="submitPropertySelection"
          variant="contained"
          type="submit"
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          {renderButtonContent({ loading, isSent })}
        </StyledButton>
      </Box>
    </Modal>
  );
};

PropertySelectionModal.propTypes = {
  values: PropTypes.shape({
    emailAddress: PropTypes.string,
    properties: PropTypes.arrayOf(
      PropTypes.shape({
        supplierName: PropTypes.string.isRequired
      })
    )
  }),
  handleSetState: PropTypes.func
};

PropertySelectionModal.defaultProps = {
  values: {
    properties: [],
    emailAddress: ''
  },
  handleSetState: () => {}
};

export default PropertySelectionModal;
