import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';
import {
  LayoutAuthedHeaderStyle,
  StyledH2,
  ControlBoxStyle,
  PartnerHubContainerStyle,
  LogoContainerStyle,
  ControlContainerStyle
} from './Header.styles';

import { colorBlue, logoColor1, logoColor2 } from '../css/colors';
import LodgeLinkLogoColor from '../logo/LodgeLinkLogoColor';

export const Header = (props) => {
  const { logout, propertyName } = props;
  return (
    <Box sx={LayoutAuthedHeaderStyle}>
      <Box sx={LogoContainerStyle}>
        <LodgeLinkLogoColor width="120px" height="40px" />
      </Box>
      <Box sx={PartnerHubContainerStyle}>
        <span className="notranslate">
          <Typography color={logoColor1} fontSize="25px" fontWeight="bold">
            Partner
          </Typography>
        </span>
        <span className="notranslate">
          <Typography color={logoColor2} fontSize="25px" fontWeight="bold">
            Hub
          </Typography>
        </span>
      </Box>
      <Box sx={ControlContainerStyle}>
        <Box sx={ControlBoxStyle}>
         <span className="notranslate"><StyledH2>{propertyName}</StyledH2></span>
          <Button name="signOut" variant="text" onClick={() => logout()}>
            <Typography sx={{ textDecoration: 'underline' }} color={colorBlue}>
              Sign out
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

Header.propTypes = {
  logout: PropTypes.func.isRequired,
  propertyName: PropTypes.string.isRequired
};
