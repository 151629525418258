import * as React from 'react';
import Box from '@mui/material/Box';
import { Twitter, Instagram, LinkedIn, Facebook } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Link, Typography, SvgIcon } from '@mui/material';
import Divider from '@mui/material/Divider';
import { colorBlue, colorWhite } from '../css/colors';
import {
  BottomContentContainerStyle,
  BottomLinksContainerStyle,
  DividerStyle,
  FooterContainerStyle,
  FooterStyle,
  GridContainerStyle,
  LinkPointerStyle,
  SocialIconStyle,
  SocialLinksContainerStyle
} from './Footer.styles';
import LodgeLinkLogoWhite from '../logo/LodgeLinkLogoWhite';

const getSocialLinks = () => {
  const socialLinks = [
    {
      href: 'https://twitter.com/lodgelink',
      iconType: <Twitter sx={SocialIconStyle} />
    },
    {
      href: 'https://ca.linkedin.com/company/lodgelink',
      iconType: <LinkedIn sx={SocialIconStyle} />
    },
    {
      href: 'https://www.facebook.com/LodgeLink/',
      iconType: <Facebook sx={SocialIconStyle} />
    },
    {
      href: 'https://www.instagram.com/lodgelink/',
      iconType: <Instagram sx={SocialIconStyle} />
    }
  ];
  return socialLinks.map((socialLink) => (
    <Link key={`link-${socialLink.href}`} href={socialLink.href} underline="none" target="_blank">
      <SvgIcon sx={{ width: '30px', margin: '3px' }}>{socialLink.iconType}</SvgIcon>
    </Link>
  ));
};

const getDateYear = () => {
  const date = new Date();
  return date.getFullYear();
};

export const Footer = () => (
    <Box sx={FooterStyle}>
      <Box sx={FooterContainerStyle}>
        <Grid container spacing={2}>
          <Grid xs={2.5} marginLeft={4} marginTop={5}>
            <LodgeLinkLogoWhite width="140px" height="50px" />
          </Grid>
          <Grid xs={2.5}>
            <Box sx={GridContainerStyle}>
              <Typography color={colorBlue} fontWeight="bold">
                RESOURCES
              </Typography>
              <Link
                color={colorWhite}
                underline="hover"
                sx={LinkPointerStyle}
                href="https://www.lodgelink.com/partnerhub-support/"
                target="_blank"
              >
                PartnerHub Support
              </Link>
            </Box>
          </Grid>
          <Grid xs={2.5}>
            <Box sx={GridContainerStyle}>
              <Typography color={colorBlue} fontWeight="bold">
                CUSTOMER SERVICE
              </Typography>
              <Link color={colorWhite} underline="hover" sx={LinkPointerStyle} href="tel:+1-844-590-5465">
                Toll Free: 1-844-590-5465
              </Link>
              <Link
                color={colorWhite}
                underline="hover"
                sx={LinkPointerStyle}
                href="https://www.lodgelink.com/contact-us/"
                target="_blank"
              >
                Contact Us
              </Link>
            </Box>
          </Grid>
          <Grid xs={2.5}>
            <Box sx={GridContainerStyle}>
              <Typography color={colorBlue} fontWeight="bold">
                FOLLOW LODGELINK
              </Typography>
              <Box sx={SocialLinksContainerStyle}>{getSocialLinks()}</Box>
            </Box>
          </Grid>
        </Grid>
        <Box sx={BottomContentContainerStyle}>
          <Divider variant="middle" sx={DividerStyle} />
          <Box sx={BottomLinksContainerStyle}>
            <Typography>{`Copyright ©${getDateYear()} LodgeLink. All rights reserved.`}</Typography>
            <span>&#8226;</span>
            <Link
              underline="hover"
              sx={LinkPointerStyle}
              href="https://www.lodgelink.com/privacy-policy/"
              target="_blank"
            >
              Privacy Policy
            </Link>
            <span>&#8226;</span>
            <Link
              underline="hover"
              sx={LinkPointerStyle}
              href="https://www.lodgelink.com/terms-of-service/"
              target="_blank"
            >
              Terms of Service
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  )
