import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button } from '@mui/material';
import { ModalStyle, TextComponentRowStyle } from './ResendOtpModal.styles';
import { colorBlue, colorGrey03 } from '../../layout/css/colors';
import withAnalytics from '../../hoc/withAnalytics';
import { useTimer } from '../../hooks/useTimer';
import { useAppState } from '../../hooks/useAppState';

const ResendOtpModal = (props) => {
  const { handleClose, showResendOtpModal, otpModalOpen, analytics } = props;
  const { appState, setAppState } = useAppState();
  const { otpDetailsMap } = appState;
  const { timers, resetBookingTimer } = useTimer();
  const { timersMap } = timers;
  const timer = timersMap.get(otpModalOpen);

  const handleRequestNewOTP = () => {
    setAppState({
      otpDetailsMap: new Map(
        otpDetailsMap.set(otpModalOpen, {
          otp: '',
          iv: '',
          verificationCode: '',
          bookingNumber: '',
          retry: false
        })
      )
    });
    resetBookingTimer(otpModalOpen);
    handleClose();

    analytics.trackGA4('track-event', {
      eventName: 'clickResendOtp',
      bookingNumber: otpModalOpen
    });
  };

  return (
    <Modal
      open={showResendOtpModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      id="otp-modal"
    >
      <Box sx={ModalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2" fontWeight="bold">
          Request new code
        </Typography>
        <Box sx={TextComponentRowStyle}>
          <Typography id="modal-modal-description" color={colorGrey03} fontWeight="strong">
            A Passcode for this booking has already been sent within the secure timeframe window (30 minutes). The
            initial passcode may still be in transit or may have been caught in your spam folder.
          </Typography>
        </Box>
        <Typography id="modal-modal-description" sx={{ mt: 2 }} color={colorGrey03} fontWeight="strong">
          Submitting a request for new passcode will deactivate the previous code.
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }} color={colorGrey03} fontWeight="bold">
          Are you sure you would like to proceed?
        </Typography>
        <Box
          sx={{
            width: '55ch',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            '& > :not(style)': { margin: 3 }
          }}
        >
          <Button name="cancelReqNewCode" onClick={handleClose} style={{ cursor: 'pointer' }}>
            {'<<'} Cancel
          </Button>

          <Button
            name="reqNewCode"
            disabled={timer > 0}
            sx={{
              color: timer > 0 ? colorGrey03 : colorBlue,
              cursor: timer > 0 ? 'default' : 'pointer'
            }}
            onClick={handleRequestNewOTP}
          >
            {timer > 0 ? (
              <Typography fontWeight="bold" fontSize="14px" style={{ color: colorBlue }}>
                Request new code in: {timer < 10 ? `0${timer} seconds` : `${timer} seconds`}
              </Typography>
            ) : (
              <Typography fontWeight="bold" fontSize="14px">
                Request new code
              </Typography>
            )}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

ResendOtpModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  showResendOtpModal: PropTypes.bool.isRequired,
  analytics: PropTypes.shape({ trackGA4: PropTypes.shape({}) }),
  otpModalOpen: PropTypes.string
};

ResendOtpModal.defaultProps = {
  analytics: {},
  otpModalOpen: ''
};

export default withAnalytics()(ResendOtpModal);
