import moment from 'moment-timezone';
/**
 * Stripping Errors from the heading message
 */
const errorPrefixString = ['GraphQL error: ', 'ApolloError: '];
export const stripError = (error) => {
  let { message = '' } = error ?? {};
  errorPrefixString.forEach((pref) => {
    if (message.startsWith(pref)) {
      message = message.substring(pref.length);
    }
  });
  return message.toString().split('\n')[0];
};

export function convertUTCDateToPropertyTime(dateInUTC, propertyTimezone) {
  const dbTime = moment.utc(dateInUTC);
  const convertedToPropertyTime = dbTime.tz(propertyTimezone).format('YYYY-MM-DD');

  return convertedToPropertyTime;
}
