import gql from 'graphql-tag';

export const AUTHENTICATE_PARTNER = gql`
  query AuthenticatePartner($token: String!) {
    PartnerHubQueries {
      authenticatePartner(token: $token) {
        __typename
        message
        token
      }
    }
  }
`;
