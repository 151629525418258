import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider } from '@mui/material';
import { RoomTableStyle, StyledRoomCard, SearchedGuestStyle, StyledRoomCardTitle } from './GuestListByRoom.styles';
import { colorGrey05 } from '../../../layout/css/colors';

const GuestListByRoom = (props) => {
  const { bookingGuests, searchedGuest, color } = props;

  const guestsByRoom = bookingGuests.reduce((acc, guest) => {
    const roomIndex = !guest.roomIndex ? 0 : guest.roomIndex;
    if (!acc[roomIndex]) {
      acc[roomIndex] = [];
    }
    acc[roomIndex].push(guest);
    return acc;
  }, {});

  return (
    <Box sx={RoomTableStyle}>
      {Object.keys(guestsByRoom).map((room, roomNumber) => (
        <StyledRoomCard key={`item-${room}`}>
          <StyledRoomCardTitle color={color}>Room {roomNumber + 1}</StyledRoomCardTitle>
          <Divider />
          {guestsByRoom[room].map((guest) => {
            const { id: guestId, firstName, lastName } = guest ?? {};
            const guestFullName = !firstName && !lastName ? `Unknown` : `${firstName} ${lastName}`;
            const highlight = searchedGuest === guestFullName;

            return (
              <Box
                key={`guest-${guestId}`}
                sx={highlight ? SearchedGuestStyle : { padding: '5px', marginLeft: '1rem' }}
              >
                {guestFullName}
              </Box>
            );
          })}
        </StyledRoomCard>
      ))}
    </Box>
  );
};

GuestListByRoom.propTypes = {
  bookingGuests: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      roomIndex: PropTypes.number
    })
  ),
  searchedGuest: PropTypes.string,
  color: PropTypes.string
};

GuestListByRoom.defaultProps = {
  bookingGuests: [
    {
      firstName: '',
      lastName: '',
      roomIndex: 0
    }
  ],
  searchedGuest: '',
  color: colorGrey05
};

export default GuestListByRoom;
