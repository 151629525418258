import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useAppState } from '../../hooks/useAppState';
import { BookingDetailsNavStyle, BookingDetailsViewStyle, StyledH1 } from './BookingDetails.style';
import { colorBlack } from '../../layout/css/colors';
import { VIEWS } from '../../buildingBlocks/enum';
import BookingSummary from '../../buildingBlocks/bookingDetails/BookingSummary';
import PaymentInfo from '../../buildingBlocks/paymentInfo/PaymentInfo';
import ScheduleDetails from '../../buildingBlocks/scheduleDetails/ScheduleDetails';
import NoticesAndInstructions from '../../buildingBlocks/noticesAndInstructions/NoticesAndInstructions';
import Transition from '../../layout/animation/Transition';

const BookingDetails = () => {
  const { appState, setAppState } = useAppState();
  const { bookingDetailsOpen: booking } = appState;
  const { hasVCC } = booking;
  const isDirectBill = localStorage.getItem('isDirectBill');
  const showPaymentInfo = hasVCC && isDirectBill === 'false'

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Box sx={BookingDetailsViewStyle}>
      <Transition>
        <Box sx={BookingDetailsNavStyle}>
          <KeyboardArrowLeftIcon
            sx={{ color: 'black', fontSize: '28px', cursor: 'pointer' }}
            onClick={() => {
              setAppState({
                currentView: VIEWS.default,
                bookingDetailsOpen: null
              });
            }}
          />
          <StyledH1 color={colorBlack} size="20px">
            <span style={{ fontSize: '20px' }}>BOOKING</span>
            <span style={{ fontSize: '16px', fontWeight: 'normal' }}>{` / ${booking.bookingNumber}`}</span>
          </StyledH1>
        </Box>
        <BookingSummary booking={booking} />
        {showPaymentInfo && <PaymentInfo bookingNumber={booking.bookingNumber} hasVCC={hasVCC} />}
        <NoticesAndInstructions bookingNumber={booking.bookingNumber} />
        <ScheduleDetails booking={booking} />
      </Transition>
    </Box>
  );
};

export default BookingDetails;
