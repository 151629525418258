import gql from 'graphql-tag';

export const QUERY_ROOM_SCHEDULE = gql`
  query PartnerGetBookingRoomSchedule($token: String!, $bookingId: Int!) {
    PartnerHubQueries {
      partnerGetBookingRoomSchedule(token: $token, bookingId: $bookingId) {
        roomIndexSummaries {
          roomIndex
          roomTypeId
          numberOfNights
          roomName
          totalRoomPrice {
            formatted
          }
          guestSchedule {
            id
            guestName
            guestDaysByRange
          }
          totalInboundRoomPrice {
            formatted
          }
        }
        inboundRate {
          value
          symbol
          position
          formatted
          name
          type
        }
      }
    }
  }
`;
