import gql from 'graphql-tag';

export const QUERY_GENERATE_OTP = gql`
  query QueryGenerateOTP($token: String!, $bookingNumber: String!, $serviceName: String!) {
    generateOTP(token: $token, bookingNumber: $bookingNumber, serviceName: $serviceName) {
      __typename
      message
      verificationCode
      iv
    }
  }
`;

export const MUTATION_AUTHENTICATE_OTP = gql`
  mutation MutationAuthenticateOtp($input: AuthenticateOTPInput!) {
    authenticateOTP(input: $input) {
      __typename
      message
    }
  }
`;
