import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import { ContentWrapper } from './styles';

const CopyWithTooltip = (content) => {
  const { textToCopy } = content;
  const [tooltipText, setTooltipText] = useState('Copy to clipboard');
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      setTooltipText('Copied!');
      setCopied(true);
      // Reset tooltip text after 2 seconds

      setTimeout(() => {
        setTooltipText('Copy to clipboard');
        setCopied(false);
      }, 2000);
    });
  };

  return !copied ? (
    <Tooltip title={tooltipText} arrow>
      <IconButton onClick={handleCopy}>
        <ContentCopyIcon />
      </IconButton>
    </Tooltip>
  ) : (
    <Tooltip title={tooltipText} arrow>
      <IconButton onClick={handleCopy}>
        <CheckIcon />
      </IconButton>
    </Tooltip>
  );
};

const CopyContent = ({ content }) => (
  <ContentWrapper>
    <CopyWithTooltip textToCopy={content} />
  </ContentWrapper>
);

CopyContent.propTypes = {
  content: PropTypes.string
};

CopyContent.defaultProps = {
  content: ''
};

export default CopyContent;
