import { colorBurgundy, admin, colorWhiteRgba } from '../css/colors';

export const LayoutSideBarStyle = {
  position: 'fixed',
  zIndex: 1500,
  bgcolor: colorBurgundy,
  height: '100%',
  width: '80px'
};

export const ListItemButtonStyle = {
  display: 'flex',
  justifyContent: 'center',
  '&:hover': {
    color: 'white',
    backgroundColor: admin.darkerTheme
  },
  '&.Mui-selected:hover': {
    color: 'white',
    backgroundColor: admin.midTheme
  },
  '&.Mui-selected': {
    color: 'white',
    backgroundColor: colorWhiteRgba(0.08)
  },
  '&.Mui-disabled': {
    opacity: 1
  }
};

export const ListItemIconStyle = { width: '100%', display: 'flex', justifyContent: 'center' };
