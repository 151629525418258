/**
 * Errors dictionary for OTP and VCC Modal
 */
const errorDictionary = {
  'The OTP entered is incorrect.': {
    errorTitle: 'INCORRECT PASSWORD',
    errorMessage: 'The One-Time Password (OTP) you entered is incorrect, please check your input and try again.'
  },
  'The OTP is expired.': {
    errorTitle: 'EXPIRED PASSWORD',
    errorMessage: 'The One-Time Password (OTP) you entered has expired. Please request a new OTP.'
  },
  'Property email error.': {
    errorTitle: 'EMAIL NOT FOUND',
    errorMessage: 'Unable to dispatch email. Please try again later. If problem persists, please contact support.'
  },
  'Email Address not found': {
    errorTitle: 'EMAIL NOT FOUND',
    errorMessage: 'Unable to dispatch email. Please try again later. If problem persists, please contact support.'
  },
  'Problem generating the OTP: Booking problem.': {
    errorTitle: 'PROBLEM GENERATING PASSWORD',
    errorMessage: 'There was an issue generating your One-Time Password (OTP). Please try again later. If problem persists, please contact support.'
  },
  'Token error': {
    errorTitle: 'TOKEN ISSUE',
    errorMessage: 'There was an issue with this token. Please refresh this page to resolve the issue.'
  },
  'Problem generating the OTP: VCC not found.': {
    errorTitle: 'VCC NOT FOUND',
    errorMessage: 'VCC could not be found. Please try again later. If problem persists, please contact support.'
  },
  'VCC not found!': {
    errorTitle: 'VCC NOT FOUND',
    errorMessage: 'VCC could not be found. Please try again later. If problem persists, please contact support.'
  },
  default: {
    errorTitle: 'UNEXPECTED ERROR',
    errorMessage: 'An unexpected error occurred. Please try again later. If problem persists, please contact support.'
  }
};

export const getErrorDetails = (errorKey) => errorDictionary[errorKey] || errorDictionary.default;
