import gql from 'graphql-tag';

export const QUERY_NOTICES_AND_INSTRUCTIONS = gql`
  query getNoticesAndInstructions($token: String!, $bookingNumber: String!) {
    PartnerHubQueries {
      bookingNoticesAndInstructions(token: $token, bookingNumber: $bookingNumber) {
        notices
        instructions
      }
    }
  }
`;
