import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Snackbar } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import { colorGrey03 } from '../../layout/css/colors';

const CopyToClipboardButton = ({ copiedText }) => {
  const [open, setOpen] = useState(false);

  const handleClick = (text) => {
    setOpen(true);
    navigator.clipboard.writeText(text);
  };

  return (
    <>
      <IconButton name="copyToClipboard" onClick={() => handleClick(copiedText)} aria-label="Copy to Clipboard">
        <ContentCopyIcon style={{ color: colorGrey03, fontSize: '18px' }} />
      </IconButton>
      <Snackbar open={open} onClose={() => setOpen(false)} autoHideDuration={2000} message="Copied to clipboard" />
    </>
  );
};

export default CopyToClipboardButton;

CopyToClipboardButton.propTypes = {
  copiedText: PropTypes.string
};

CopyToClipboardButton.defaultProps = {
  copiedText: ''
};
