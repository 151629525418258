import React, { useState } from 'react';
import { Autocomplete, Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import GuestListByRoom from './guestListByRoom/GuestListByRoom';
import { colorGrey04, colorGrey05 } from '../../layout/css/colors';
import InfoContainer from '../containers/infoContainer/InfoContainer';
import IndividualGuestSchedule from './individualGuestSchedule/IndividualGuestSchedule';

const ScheduleDetails = (props) => {
  const { booking } = props;
  const { guests, id: bookingId = 0 } = booking ?? {};

  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const displayGuests = guests
    .filter((guest) => guest.firstName != null && guest.lastName != null)
    .map((guest) => `${guest.firstName} ${guest.lastName}`);

  return (
    <InfoContainer title="Booking and Schedule Details">
      <Box style={{ padding: '1rem' }}>
        <Typography variant="h7" fontWeight="bold">
          Guest List By Room
        </Typography>
        <span className="notranslate">
          <Autocomplete
            value={value}
            inputValue={inputValue}
            onChange={(_, newValue) => {
              setValue(newValue);
            }}
            onInputChange={(e, newInputValue) => {
              setInputValue(newInputValue);
            }}
            id="guest-name"
            options={displayGuests}
            noOptionsText="No assigned guest names"
            renderInput={(params) => {
              const {
                InputLabelProps,
                InputProps,
                inputProps,
                disabled: renderInputDisabled,
                fullWidth,
                id,
                size
              } = params;
              return (
                <TextField
                  id={id}
                  size={size}
                  disabled={renderInputDisabled}
                  fullWidth={fullWidth}
                  inputProps={inputProps}
                  InputProps={InputProps}
                  InputLabelProps={InputLabelProps}
                  label="Guest Name"
                  variant="outlined"
                  name="guestNameSearch"
                />
              );
            }}
            style={{ padding: '1rem 0 1.5rem 0' }}
          />
        </span>
        <Box style={{ backgroundColor: colorGrey05 }}>
          <span className="notranslate"><GuestListByRoom bookingGuests={guests} searchedGuest={inputValue} color={colorGrey04} /></span>
        </Box>
      </Box>
      <Box style={{ padding: '1rem' }}>
        <Typography variant="h7" fontWeight="bold">
          Individual Guest Schedule
        </Typography>
        <Box>
          <IndividualGuestSchedule bookingId={bookingId} />
        </Box>
      </Box>
    </InfoContainer>
  );
};

ScheduleDetails.propTypes = {
  booking: PropTypes.shape({
    guests: PropTypes.arrayOf(
      PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string
      })
    )
  })
};

ScheduleDetails.defaultProps = {
  booking: {
    guests: []
  }
};

export default ScheduleDetails;
