import * as React from 'react';
import PropTypes from 'prop-types';
import { logoColor1, logoColor2, logoColor3, logoColor4 } from '../css/colors';

const LodgeLinkLogoColor = (props) => {
  const { height, width, backgroundColor, iconColor1, iconColor2, iconColor3, iconColor4 } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 162 51" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Atoms" stroke="none" strokeWidth="1" fill={backgroundColor} fillRule="evenodd">
        <g id="Logo/LodgeLink/Colour" fillRule="nonzero">
          <g id="LodgeLink_Colour_Logo">
            <polygon
              id="Shape"
              fill={iconColor1}
              points="4.326875 30.09 0 30.09 0 50.461978 12.3824653 50.461978 12.3824653 46.1914286 4.326875 46.1914286"
            />
            <polygon
              id="Shape"
              fill={iconColor1}
              points="79.5720139 50.461978 92.8489236 50.461978 92.8489236 46.1914286 83.8988889 46.1914286 83.8988889 42.33 91.1438889 42.33 91.1438889 38.0594505 83.8988889 38.0594505 83.8988889 34.3549451 92.8489236 34.3549451 92.8489236 30.09 79.5720139 30.09"
            />
            <path
              d="M24.0661458,29.8602198 C19.8668563,29.8579527 16.0797985,32.3921874 14.4712328,36.2809723 C12.8626671,40.1697572 13.7494535,44.6470683 16.7180002,47.6247154 C19.6865469,50.6023624 24.1521011,51.4937998 28.0319558,49.8832668 C31.9118105,48.2727337 34.4417008,44.4774775 34.4417014,40.2675824 C34.4431845,37.5078937 33.3507036,34.8607258 31.4047487,32.9088054 C29.4587938,30.9568851 26.8188831,29.8602194 24.0661458,29.8602198 Z M24.0661458,46.3259341 C21.6214365,46.3282013 19.4161914,44.8535697 18.4790742,42.5898992 C17.541957,40.3262287 18.0575974,37.719505 19.7854685,35.9856663 C21.5133397,34.2518276 24.1130186,33.7324728 26.371853,34.6698611 C28.6306875,35.6072495 30.1036448,37.8166981 30.1036458,40.2675824 C30.1036473,43.6113302 27.4014691,46.3228409 24.0661458,46.3259341 Z"
              id="Shape"
              fill={iconColor1}
            />
            <path
              d="M68.8889931,40.5085714 L68.8889931,43.5069231 L71.3039931,43.5069231 L71.3039931,45.8215385 C70.1914012,46.1702771 69.0316555,46.3442056 67.8659722,46.3371429 C65.1288602,46.3466818 62.7271238,44.5106864 62.0118581,41.8619945 C61.2965925,39.2133026 62.4464217,36.4133748 64.8145101,35.0373086 C67.1825985,33.6612423 70.1775609,34.0526841 72.1145833,35.9914286 L75.1780556,32.9202198 C71.8552612,29.5885422 66.7143652,28.9102607 62.6448775,31.2666125 C58.5753899,33.6229643 56.5924537,38.4261521 57.810605,42.9764793 C59.0287563,47.5268065 63.1441243,50.6891836 67.8436111,50.6861538 C72.9754861,50.6861538 74.7308333,48.8815385 75.6029167,47.9848352 L75.6029167,40.5085714 L68.8889931,40.5085714 Z"
              id="Shape"
              fill={iconColor1}
            />
            <path
              d="M45.2141667,30.0731868 L37.2480208,30.0731868 L37.2480208,50.4563736 L45.2141667,50.4563736 C50.830193,50.4563736 55.3828819,45.8921872 55.3828819,40.261978 C55.3828819,34.6317688 50.830193,30.0675824 45.2141667,30.0675824 L45.2141667,30.0731868 Z M45.2141667,46.1241758 L41.5916667,46.1241758 L41.5916667,34.4334066 L45.2141667,34.4334066 C47.2972608,34.4334065 49.2221193,35.5475305 50.2636664,37.3560988 C51.3052135,39.1646671 51.3052135,41.3929153 50.2636664,43.2014836 C49.2221193,45.010052 47.2972608,46.1241759 45.2141667,46.1241758 Z"
              id="Shape"
              fill={iconColor1}
            />
            <polygon
              id="Shape"
              fill={iconColor2}
              points="101.558576 30.09 97.2317014 30.09 97.2317014 50.461978 109.614167 50.461978 109.614167 46.1914286 101.558576 46.1914286"
            />
            <rect id="Rectangle-path" fill={iconColor2} x="143.373854" y="30.09" width="4.326875" height="20.371978" />
            <polygon
              id="Shape"
              fill={iconColor2}
              points="153.268646 39.9873626 153.268646 39.9873626 160.563958 30.0956044 155.834583 30.09 148.494549 40.0041758 156.231493 50.461978 161 50.461978"
            />
            <rect id="Rectangle-path" fill={iconColor2} x="112.481979" y="30.09" width="4.326875" height="20.371978" />
            <polygon
              id="Shape"
              fill={iconColor2}
              points="121.191632 30.09 121.191632 50.4675824 125.518507 50.4675824 125.518507 36.9385714 135.307083 50.4675824 138.951944 50.4675824 138.951944 30.09 134.625069 30.09 134.625069 42.2067033 125.943368 30.09"
            />
            <rect
              id="Rectangle-path"
              fill={iconColor3}
              transform="translate(84.045189, 16.536206) rotate(45.000000) translate(-84.045189, -16.536206) "
              x="80.1571508"
              y="12.638349"
              width="7.77607639"
              height="7.79571429"
            />
            <rect
              id="Rectangle-path"
              fill={iconColor2}
              transform="translate(89.539756, 11.025444) rotate(45.000000) translate(-89.539756, -11.025444) "
              x="85.6517176"
              y="7.12758665"
              width="7.77607639"
              height="7.79571429"
            />
            <rect
              id="Rectangle-path"
              fill={iconColor1}
              transform="translate(95.039913, 5.512360) rotate(45.000000) translate(-95.039913, -5.512360) "
              x="91.1518748"
              y="1.61450286"
              width="7.77607639"
              height="7.79571429"
            />
            <rect
              id="Rectangle-path"
              fill={iconColor4}
              transform="translate(95.040589, 16.535528) rotate(45.000000) translate(-95.040589, -16.535528) "
              x="91.152551"
              y="12.6376711"
              width="7.77607639"
              height="7.79571429"
            />
            <rect
              id="Rectangle-path"
              fill={iconColor2}
              transform="translate(100.540746, 11.024766) rotate(45.000000) translate(-100.540746, -11.024766) "
              x="96.6527081"
              y="7.12690873"
              width="7.77607639"
              height="7.79571429"
            />
            <rect
              id="Rectangle-path"
              fill={iconColor3}
              transform="translate(106.039942, 16.538813) rotate(45.000000) translate(-106.039942, -16.538813) "
              x="102.151904"
              y="12.6409561"
              width="7.77607639"
              height="7.79571429"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

LodgeLinkLogoColor.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  backgroundColor: PropTypes.string,
  iconColor1: PropTypes.string,
  iconColor2: PropTypes.string,
  iconColor3: PropTypes.string,
  iconColor4: PropTypes.string
};

LodgeLinkLogoColor.defaultProps = {
  height: 51,
  width: 162,
  backgroundColor: 'transparent',
  iconColor1: logoColor1,
  iconColor2: logoColor2,
  iconColor3: logoColor3,
  iconColor4: logoColor4
};

export default LodgeLinkLogoColor;
