import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {
  ModalHeaderStyle,
  ModalStyle,
  ModalFooterContentStyle,
  ModalIconStyle,
  StyledButton,
  ModalBodyStyle,
  ButtonTypography,
  BodyTypography,
  HeaderTypography
} from './SecurityNudgeModal.styles';

const SecurityNudge = (props) => {
  const { securityNudgeModalOpen, logout, handleClose } = props;
  return (
    <Modal
      open={securityNudgeModalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ModalStyle}>
        <Box sx={ModalHeaderStyle}>
          <Box sx={ModalIconStyle}>!</Box>
          <HeaderTypography>Security Warning</HeaderTypography>
        </Box>
        <Box sx={ModalBodyStyle}>
          <BodyTypography>
            You have not used <span style={{ fontWeight: 600 }}>PartnerHub</span> for more than 30 minutes. To ensure
            payment method security please remember to logout once you are done using the PartnerHub. Please confirm
            that you wish to keep working or Sign Out.
          </BodyTypography>
        </Box>
        <br />
        <Box sx={ModalFooterContentStyle}>
          <StyledButton primary onClick={handleClose}>
            <ButtonTypography variant="button">Keep Working</ButtonTypography>
          </StyledButton>
          <StyledButton
            name="signOut"
            variant="text"
            onClick={() => {
              logout();
              handleClose();
            }}
          >
            <ButtonTypography>Sign out</ButtonTypography>
          </StyledButton>
        </Box>
      </Box>
    </Modal>
  );
};

SecurityNudge.propTypes = {
  securityNudgeModalOpen: PropTypes.string,
  logout: PropTypes.func,
  handleClose: PropTypes.func
};

SecurityNudge.defaultProps = {
  securityNudgeModalOpen: false,
  logout: () => {},
  handleClose: () => {}
};

export default SecurityNudge;
