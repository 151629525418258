import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';
import get from 'lodash.get';
import * as yup from 'yup';
import { Link, Modal } from '@mui/material';
import {
  AuthTextStyle,
  LoginContainerStyle,
  LoginFormStyle,
  ModalStyle,
  NotificationContainerStyle,
  StyledButton,
  StyledH1,
  StyledH2
} from './Login.styles';
import { INITIATE_PARTNER_AUTHENTICATION } from './Login.gql';
import { colorBlue } from '../../layout/css/colors';
import PropertySelectionModal from '../../buildingBlocks/propertySelection/PropertySelectionModal';
import reducer from '../../reducer/state.reducer';
import withAnalytics from '../../hoc/withAnalytics';

const getProperties = (data) => get(data, 'PartnerHubMutations.initiatePartnerAuthentication.properties');

const renderButtonContent = ({ loading, multiPropertyGenLoading, isSent, error }) => {
  if (loading || multiPropertyGenLoading) return <CircularProgress />;
  if (isSent && error) return `Re-send authorization email`;
  return `Verify email`;
};

const Login = (props) => {
  const { analytics } = props;

  const initialState = {
    sentToEmailAddress: '',
    isSent: false,
    whyModal: false,
    propertySelectionValues: null,
    multiPropertyGen: { error: '', data: '', loading: '' }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleSetState = (newValue) => {
    dispatch({
      type: 'SET_STATE',
      newValue
    });
  };


  const schema = yup.object().shape({
    emailAddress: yup.string().email('Invalid Email format.').required('Email address is required')
  });

  const resetIsSent = () => {
    handleSetState({
      isSent: false,
      multiPropertyGen: { error: '', data: '', loading: '' }
    });
  };

  const formik = useFormik({
    initialValues: {
      emailAddress: ''
    },
    onSubmit: async (values) => {
      try {
        const { emailAddress } = values;
        resetIsSent();
        // eslint-disable-next-line no-use-before-define
        await initiatePartnerAuthentication({
          variables: { emailAddress }
        });
      } catch (onSubmitError) {
        // Swallow the error
      } finally {
        formik.resetForm();
      }
    },
    validationSchema: schema,
    validateOnChange: false
  });

  const [initiatePartnerAuthentication, { error, data, loading }] = useMutation(INITIATE_PARTNER_AUTHENTICATION, {
    onCompleted: (onCompletedData) => {
      const properties = getProperties(onCompletedData);
      if (properties.length)
        handleSetState({
          propertySelectionValues: {
            emailAddress: formik.values.emailAddress,
            properties
          }
        });
    }
  });

  const { sentToEmailAddress, isSent, whyModal, propertySelectionValues, multiPropertyGen } = state;

  useEffect(() => {
    handleSetState({
      isSent: !!error || (!!data && !getProperties(data).length) || !!multiPropertyGen.error || !!multiPropertyGen.data
    });
  }, [error, data, multiPropertyGen]);

  const renderDialog = () => {
    if (formik.errors.emailAddress) return <StyledH1 color="red">{formik.errors.emailAddress}</StyledH1>;
    if (error || multiPropertyGen.error)
      return (
        <>
          <StyledH1>Email Address could not be verified.</StyledH1>
          <StyledH1>Please try with a different Email Address.</StyledH1>
        </>
      );
    return (
      <>
        <StyledH1 bold>Thank you!</StyledH1>
        <StyledH1>
          <span>An authorized link to your PartnerHub has been sent to&nbsp;</span>
          <span style={{ color: colorBlue, fontWeight: 'bold' }}>{sentToEmailAddress}</span>
        </StyledH1>
        <StyledH1>Not there?</StyledH1>
        <StyledH1>Make sure to check your spam folder and email address</StyledH1>
      </>
    );
  };

  return (
    <Box sx={LoginContainerStyle}>
      <Box sx={AuthTextStyle}>
        <StyledH1 size="25px">Email Authentication Required&nbsp;</StyledH1>
        <StyledH1
          size="25px"
          color={colorBlue}
          onClick={() => handleSetState({ whyModal: true })}
          style={{ cursor: 'pointer' }}
        >
          (why?)
        </StyledH1>
      </Box>
      {whyModal && (
        <Modal open onClose={() => handleSetState({ whyModal: false })}>
          <Box sx={ModalStyle}>
            <StyledH2 size="20px">
              {`By providing your front desk email registered with LodgeLink and obtaining a secure login link, 
                we’ve removed the need for yet another password to remember. 
                This also provides an extra layer of security to protect you and your organization’s financial liabilities.`}
            </StyledH2>
          </Box>
        </Modal>
      )}
      {propertySelectionValues && (
        <PropertySelectionModal values={propertySelectionValues} handleSetState={handleSetState} />
      )}
      <Box sx={LoginFormStyle}>
        <TextField
          sx={{ width: '50ch' }}
          id="emailAddress"
          name="emailAddress"
          label="Email Address"
          variant="outlined"
          title="emailAddress"
          value={formik.values.emailAddress}
          error={!!formik.errors.emailAddress && formik.submitCount > 0}
          onChange={(e) => formik.setFieldValue('emailAddress', e.target.value)}
        />
        <StyledButton
          name="submit"
          variant="contained"
          type="submit"
          onClick={() => {
            analytics.trackGA4('track-event', {
              eventName: 'emailVerificationRequested'
            });
            handleSetState({ sentToEmailAddress: formik.values.emailAddress });
            formik.handleSubmit(formik.values, initiatePartnerAuthentication);
          }}
        >
          {renderButtonContent({ loading, multiPropertyGenLoading: multiPropertyGen.loading, error, isSent })}
        </StyledButton>
        {(isSent || formik.errors.emailAddress) && <Box sx={NotificationContainerStyle}>{renderDialog()}</Box>}
        <StyledH1>
          <span>If you are looking to list your property with LodgeLink&nbsp;</span>
          <span>
            <Link style={{ color: colorBlue }} href="https://www.lodgelink.com/becoming-a-supplier/" target="_blank">
              click here to contact us
            </Link>
          </span>
        </StyledH1>
      </Box>
    </Box>
  );
};

Login.propTypes = {
  analytics: PropTypes.shape({ trackGA4: PropTypes.shape({}) })
};

Login.defaultProps = {
  analytics: {}
};

export default withAnalytics()(Login);
