import gql from 'graphql-tag';

export const SEARCH_BOOKINGS = gql`
  query SEARCH_BOOKINGS($token: String!, $bookingNumber: String!, $draftBooking: Boolean) {
    PartnerHubQueries {
      partnerBookingTypeahead(token: $token, bookingNumber: $bookingNumber, draftBooking: $draftBooking) {
        id
        bookingNumber
        confirmationNumber
      }
    }
  }
`;

export const SEARCH_CONFIRMATION_NUMBER = gql`
  query SEARCH_CONFIRMATION_NUMBER($token: String!, $confirmationNumber: String!, $draftBooking: Boolean) {
    PartnerHubQueries {
      partnerConfirmationNumberTypeahead(
        token: $token
        confirmationNumber: $confirmationNumber
        draftBooking: $draftBooking
      ) {
        id
        bookingNumber
        confirmationNumber
      }
    }
  }
`;

export const SEARCH_BOOKING_GUEST = gql`
  query SEARCH_BOOKING_GUEST($token: String!, $guestName: String!, $orgId: Int) {
    PartnerHubQueries {
      partnerGuestTypeahead(token: $token, guestName: $guestName, orgId: $orgId) {
        id
        firstName
        lastName
        booking {
          id
          organization {
            id
            name
          }
        }
      }
    }
  }
`;

export const SEARCH_CUSTOMERS = gql`
  query SEARCH_CUSTOMERS($token: String!, $customerName: String!) {
    PartnerHubQueries {
      partnerCustomerTypeahead(token: $token, customerName: $customerName) {
        id
        name
      }
    }
  }
`;
