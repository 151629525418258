import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, SvgIcon } from '@mui/material';
import { BottomBlockStyle, MidBlockStyle, TopBlockStyle, VCCLogo, VCCLogoStyle, VCCStyle } from './VirtualCard.styles';
import { ReactComponent as Logo } from './img/logo.svg';
import { VirtualCard as vccStyles } from '../../../layout/css/colors';

const VirtualCard = (props) => {
  const { vcc, isCSI, isCA } = props;

  return (
    <Box sx={VCCStyle(isCA)}>
      <Box sx={TopBlockStyle}>
        <SvgIcon sx={{ width: '20.108mm', mt: '5mm' }} inheritViewBox>
          <Logo width="20.108mm" height="6.315mm" />
        </SvgIcon>
        <Typography fontSize="11.2pt" color={vccStyles.textColour} sx={{ pt: '7mm' }}>
          Virtual Card
        </Typography>
      </Box>
      <Box sx={MidBlockStyle}>
        <Typography fontSize="6.1pt" sx={{ mb: '2mm' }}>
          Customer Service 1-844-590-5465
        </Typography>
        <Typography fontSize="6.1pt">Cardholder Name</Typography>
        <Typography fontSize="10pt" sx={{ mt: '-1mm', mb: '1mm' }}>
          {vcc.cardholderName}
        </Typography>
        <Typography fontSize="6.1pt">Account Number</Typography>
        <Typography fontSize="10pt" sx={{ mt: '-1mm', mb: '1mm' }}>
          {vcc.cardNumber}
        </Typography>
      </Box>
      <Box sx={BottomBlockStyle}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box>
              <Typography fontSize="6.1pt">CVV2</Typography>
              <Typography fontSize="10pt" sx={{ mt: '-1mm', mb: '1mm' }}>
                {vcc.cvv}
              </Typography>
            </Box>
            <Box sx={{ marginLeft: '5mm' }}>
              <Typography fontSize="6.1pt">GoodThru</Typography>
              <Typography fontSize="10pt" sx={{ mt: '-1mm', mb: '1mm' }}>
                {vcc.expiry.replace('-', '/')}
              </Typography>
              <Typography fontSize="5pt" sx={{ mt: '-3mm' }} variant="subtitle1">
                Year &nbsp; Month
              </Typography>
            </Box>
          </Box>
          <Typography fontSize="10pt" fontWeight="bold" sx={{ mb: '4mm' }}>
            Limited Use
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
          <SvgIcon sx={VCCLogoStyle(isCA)} inheritViewBox>
            {VCCLogo(isCSI, isCA)}
          </SvgIcon>
        </Box>
      </Box>
    </Box>
  );
};

VirtualCard.propTypes = {
  vcc: PropTypes.shape({
    cardholderName: PropTypes.string.isRequired,
    cardNumber: PropTypes.string.isRequired,
    expiry: PropTypes.string.isRequired,
    cvv: PropTypes.string.isRequired
  }).isRequired,
  isCSI: PropTypes.bool.isRequired,
  isCA: PropTypes.bool
};

VirtualCard.defaultProps = {
  isCA: true
};

export default VirtualCard;
