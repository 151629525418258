import React from 'react';
import Box from '@mui/material/Box';
import { fontRegular } from '../../layout/css/fonts';
import { H1Container, LogoContainer, StyledH1 } from './NotFound.styles';
import LodgeLinkLogoColor from '../../layout/logo/LodgeLinkLogoColor';

const NotFound = () => (
  <Box sx={{ fontFamily: fontRegular }}>
    <Box
      sx={{
        height: '50vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        textAlign: 'center'
      }}
    >
      <LogoContainer>
        <LodgeLinkLogoColor width="210px" height="70px" />
      </LogoContainer>
      <H1Container>
        <StyledH1 primary="true">404 Not &nbsp;</StyledH1>
        <StyledH1>Found</StyledH1>
      </H1Container>
    </Box>
  </Box>
);

export default NotFound;
