import styled from 'styled-components';
import { colors } from './css';
import { fontRegular } from './css/fonts';

export const LayoutAuthedRootStyle = { fontFamily: fontRegular };

export const LayoutRootStyle = { fontFamily: fontRegular };

export const LayoutAuthedBodyStyle = {
  bgcolor: '#e4e4e4',
  marginTop: '90px',
  marginLeft: '80px',
  width: { lg: '100%', md: 'calc(100% - 80px)' }
};

export const LayoutBodyStyle = {
  bgcolor: colors.colorWhite,
  height: '100vh',
  width: 'calc(100% - 400px)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'center'
};

export const LayoutBodyContainerStyle = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh'
};

export const LayoutAuthedBodyContainerStyle = {
  display: 'flex',
  flexDirection: 'row',
  minHeight: '100vh'
};

export const WelcomeContinerStyle = {
  display: 'flex',
  flexDirection: 'row',
  margin: '10px',
  marginTop: '10px'
};

export const StyledH2 = styled.h2`
  background: white;
  color: ${colors.colorGrey03};
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  padding: 0;
`;

export const LogoContainerStyle = {
  margin: '25px',
  marginTop: '200px',
  marginLeft: '50px'
};

export const ControlBoxStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'center'
};

export const FrontDeskBellStyle = {
  height: 100,
  width: 100
};

export const SideImageStyle = { position: 'fixed', right: 0, maxHeight: '100%', maxWidth: '30%', zIndex: 1000 };
