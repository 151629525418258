import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const AutoComplete = (props) => {
  const { value, data, loading, initialTerm, updateSearchTerm, label, setValue, disabled, name } = props;
  const [term, setTerm] = useState(initialTerm);
  return (
    <Autocomplete
      // suggestions return from query
      value={value}
      key={name}
      inputValue={term}
      filterOptions={(x) => x}
      freeSolo
      options={data}
      loading={loading} // query loading state
      disabled={disabled ?? false}
      onInputChange={(_, newInputValue) => {
        updateSearchTerm(newInputValue);
        setTerm(newInputValue);
      }}
      onChange={(_, newValue) => {
        setValue(newValue);
      }}
      renderInput={(params) => {
        const { InputLabelProps, InputProps, inputProps, disabled: renderInputDisabled, fullWidth, id, size } = params;
        return (
          <TextField
            key={name}
            id={id}
            size={size}
            disabled={renderInputDisabled}
            fullWidth={fullWidth}
            inputProps={inputProps}
            InputProps={InputProps}
            InputLabelProps={InputLabelProps}
            name={name}
            label={label}
            variant="outlined"
            // update search term state on field change
          />
        );
      }}
    />
  );
};

AutoComplete.defaultProps = {
  value: {
    id: 0,
    label: ''
  }
};

AutoComplete.propTypes = {
  value: PropTypes.shape({}),
  initialTerm: PropTypes.string.isRequired,
  updateSearchTerm: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string
    })
  ).isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default AutoComplete;
