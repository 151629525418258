import { styled as styledMUI } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { colors } from '../../layout/css';
import { colorWhite } from '../../layout/css/colors';

export const ButtonState = {
    default: colors.logoColor3,
    accessed: colors.logoColor2,
    disabled: colors.colorGrey03,
    clicked: colors.admin.midTheme
  };
  
  export const StyledVCCButton = styledMUI(Button)((props) => ({
    color: colorWhite,
    backgroundColor: props.state ?? colors.logoColor3,
    '&:hover': {
      backgroundColor: props.state ?? colors.logoColor3,
      opacity: 0.5
    },
    padding: 5,
    marginTop: 5,
    marginBottom: 5,
    fontWeight: 'strong',
    fontSize: '14px',
    width: '120px'
  }));