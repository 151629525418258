import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  Box,
  CircularProgress
} from '@mui/material';
import { colorGrey05, colorGreen, colorRed } from '../../layout/css/colors';
import { LoadingStyle, StyledH1, StyledH2, TableLayoutStyle } from '../bookingTable/BookingTable.styles';
import withAnalytics from '../../hoc/withAnalytics';
import Transition from '../../layout/animation/Transition';

const TableComponent = (props) => {
  const { searchInput, pagination, handleSetState, tableColumns, error, tableData, loading, totalCount } = props;

  const { page, rowsPerPage } = pagination;

  const handleChangePage = (_, newPage) => {
    handleSetState({
      searchSubmit: true,
      loading: true,
      searchInput: {
        ...searchInput,
        skip: newPage * rowsPerPage
      },
      pagination: {
        ...pagination,
        page: newPage
      }
    });
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    handleSetState({
      searchSubmit: true,
      loading: true,
      searchInput: {
        ...searchInput,
        top: newRowsPerPage
      },
      pagination: {
        page: 0,
        rowsPerPage: newRowsPerPage
      }
    });
  };

  const renderTable = () => {
    const getCellColor = (columnId, value) => {
      if (columnId === 'actionType') {
        return value === 'Check-in' ? colorGreen : colorRed;
      }
      return 'inherit';
    };
    if (error)
      return <Box sx={{ display: 'flex', justifyContent: 'center', m: 2, width: '100%' }}>No Records Found</Box>;
    if (loading)
      return (
        <Box sx={LoadingStyle}>
          <CircularProgress />
        </Box>
      );
    return (
      <TableContainer>
        <Transition>
          {tableData.length ? (
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {tableColumns.map((column) => (
                    <TableCell
                      key={`column-${column.id}`}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData?.map((item) => (
                  <TableRow
                    hover
                    sx={{ '& > *': { borderBottom: 'unset' } }}
                    bgcolor="transparent"
                    key={`column-${item.id}`}
                  >
                    {tableColumns.map((column) => (
                      <TableCell
                        key={`column-${column.id}`}
                        align="left"
                        style={{
                          color: getCellColor(column.id, item[column.id])
                        }}
                      >
                        {item[column.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <StyledH2>No result. Please enter search.</StyledH2>
          )}
        </Transition>
      </TableContainer>
    );
  };
  return (
    <Box margin="10px" marginTop="20px">
      <Box
        bgcolor={colorGrey05}
        height="40px"
        sx={{
          '& > :not(style)': { m: 1, p: 1 }
        }}
      >
        <StyledH1 size="16px">Results</StyledH1>
      </Box>
      <Paper sx={TableLayoutStyle}>
        {renderTable()}
        <TablePagination
          rowsPerPageOptions={[9, 18, 27]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

TableComponent.propTypes = {
  analytics: PropTypes.shape({}),
  tableData: PropTypes.shape([{}]),
  tableColumns: PropTypes.shape([{ id: PropTypes.string, label: PropTypes.string, minWidth: PropTypes.number }]),
  searchInput: PropTypes.shape({ startDate: PropTypes.string, endDate: PropTypes.string }),
  pagination: PropTypes.shape({ page: PropTypes.number, rowsPerPage: PropTypes.number }),
  handleSetState: PropTypes.func,
  error: PropTypes.bool,
  totalCount: PropTypes.number,
  loading: PropTypes.bool
};

TableComponent.defaultProps = {
  tableData: [],
  totalCount: 0,
  loading: false,
  error: false,
  tableColumns: [],
  analytics: {},
  searchInput: { startDate: '', endDate: '' },
  pagination: {
    rowsPerPage: 9,
    page: 0
  },
  handleSetState: () => {}
};

export default withAnalytics()(TableComponent);
