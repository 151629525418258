import styled from 'styled-components';
import { colorBlack, colorGrey, colorGrey03 } from './colors';
/**
 * Global font variables
 */
export const baseFontSize = 16;

//  Example Font Family
export const fontRegular = 'AvenirNextLTPro-Regular,arial,helvetica,verdana,sans-serif';
export const fontMedium = 'AvenirNextLTPro-Medium,arial,helvetica,verdana,sans-serif';
export const fontBold = 'AvenirNextLTPro-Demi,arial,helvetica,verdana,sans-serif';

export const StyledText = styled.div`
  text-align: center;
  color: ${colorGrey03};
  margin: 15px;
  font-size: 20px;
  font-weight: 600;
`;

export const NormalText = styled.p`
  color: ${colorBlack};
  font-family: ${fontRegular};
  margin: 0;
  font-size: 1rem;
`;

export const SmallText = styled.p`
  color: ${colorBlack};
  font-family: ${fontRegular};
  margin: 0;
  font-size: 14px;
`;

export const BoldText = styled(NormalText)`
  font-family: ${fontMedium};
`;

export const AccentText = styled(NormalText)`
  font-size: 0.8rem;
`;

export const LargeText = styled(NormalText)`
  font-size: 1.2rem;
`;

export const AccentLargeText = styled(LargeText)`
  color: ${colorGrey};
`;

export const BoldLargeText = styled(LargeText)`
  color: ${colorBlack};
  font-family: ${fontMedium};
  margin: 0;
`;

export const LightAccentText = styled(AccentText)`
  color: ${colorGrey};
`;

export default {
  baseFontSize,
  fontRegular,
  fontMedium,
  fontBold,
  NormalText,
  SmallText,
  LargeText,
  AccentLargeText,
  BoldText,
  AccentText,
  LightAccentText,
  BoldLargeText,
  StyledText
};
