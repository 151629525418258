import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useAppState } from '../../hooks/useAppState';
import { useTimer } from '../../hooks/useTimer';
import VCCModal from '../vccModal/VCCModal';
import OTPModal from '../otpModal/OTPModal';
import { StyledH2, PaymentInfoStyle } from './PaymentInfo.styles';
import { colorWhite } from '../../layout/css/colors';
import withAnalytics from '../../hoc/withAnalytics';
import VCCButton from '../vccButton/VCCButton';
import InfoContainer from '../containers/infoContainer/InfoContainer';

const PaymentInformation = (props) => {
  const { analytics, bookingNumber, hasVCC } = props;
  const { appState } = useAppState();
  const { otpDetailsMap } = appState;
  const [state, setState] = useState({ vccModalOpen: '', otpModalOpen: '' });
  const { vccModalOpen, otpModalOpen } = state;
  const { resetBookingTimer } = useTimer();

  return (
    <InfoContainer title="Payments">
      <Box sx={PaymentInfoStyle} bgcolor={colorWhite}>
        <StyledH2>Get Virtual Credit Card Information For This Booking</StyledH2>

        <Typography marginBottom="15px">
          {`To access LodgeLink's Virtual Credit Card(VCC). Please click the button below.`}
        </Typography>

        <VCCButton
          bookingNumber={bookingNumber}
          otpDetailsMap={otpDetailsMap}
          setState={setState}
          resetBookingTimer={resetBookingTimer}
          hasVCC={hasVCC}
        />

        {vccModalOpen && !otpModalOpen && <VCCModal vccModalOpen={vccModalOpen} handleSetState={setState} />}
        {otpModalOpen && (
          <OTPModal
            otpModalOpen={otpModalOpen}
            handleSetState={setState}
            resetBookingTimer={resetBookingTimer}
            analytics={analytics}
          />
        )}
      </Box>
    </InfoContainer>
  );
};

PaymentInformation.propTypes = {
  bookingNumber: PropTypes.string,
  analytics: PropTypes.shape({}),
  hasVCC: PropTypes.bool
};

PaymentInformation.defaultProps = {
  bookingNumber: '',
  analytics: {},
  hasVCC: false
};

export default withAnalytics()(PaymentInformation);
