// src/context/secondClient.context.js
import React from 'react';
import { ApolloClient, InMemoryCache } from '@apollo/client';

// Define the secondary Apollo Client
export const crewRegistration = new ApolloClient({
  uri: process.env.REACT_APP_CREW_REGISTRATION_CONTAINER_APP,
  cache: new InMemoryCache()
});

// Create the context
export const CrewRegistrationApolloClientContext = React.createContext(crewRegistration);
