import * as React from 'react';
import PropTypes from 'prop-types';
import { logoWhite } from '../css/colors';

const LodgeLinkLogoWhite = (props) => {
  const { height, width, backgroundColor, iconColor } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 162 51" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <polygon
          id="path-1"
          points="0.264350444 0.482034066 21.155625 0.482034066 21.155625 21.2967033 0.264350444 21.2967033"
        />
        <polygon
          id="path-3"
          points="0.33069375 0.557076923 9.80488125 0.557076923 9.80488125 9.99644835 0.33069375 9.99644835"
        />
      </defs>
      <g id="Atoms" stroke="none" strokeWidth="1" fill={backgroundColor} fillRule="evenodd">
        <g id="Logo/LodgeLink/White">
          <g id="LodgeLink_White_Logo">
            <polygon
              id="Fill-1"
              fill={iconColor}
              points="97.8353438 29.8524297 97.8353438 50.2244077 110.295281 50.2244077 110.295281 45.9594626 102.189094 45.9594626 102.189094 29.8524297"
            />
            <polygon
              id="Fill-2"
              fill={iconColor}
              points="113.180625 50.2242956 117.534375 50.2242956 117.534375 29.8523176 113.180625 29.8523176"
            />
            <polygon
              id="Fill-3"
              fill={iconColor}
              points="121.944487 29.857978 121.944487 50.229956 126.298237 50.229956 126.298237 36.7009451 136.147612 50.229956 139.81455 50.229956 139.81455 29.857978 135.461363 29.857978 135.461363 41.9752418 126.725737 29.8523736"
            />
            <polygon
              id="Fill-4"
              fill={iconColor}
              points="144.263813 50.2242956 148.618125 50.2242956 148.618125 29.8523176 144.263813 29.8523176"
            />
            <polygon
              id="Fill-5"
              fill={iconColor}
              points="149.41665 39.7718736 157.20165 50.2246319 161.999775 50.2246319 154.2204 39.7556209 161.561025 29.8633022 156.802275 29.8526538"
            />
            <polygon
              id="Fill-6"
              fill={iconColor}
              points="0 29.8524297 0 50.2244077 12.459375 50.2244077 12.459375 45.9594626 4.35375 45.9594626 4.35375 29.8524297"
            />
            <polygon
              id="Fill-7"
              fill={iconColor}
              points="80.0659687 29.8524297 80.0659687 50.2244077 93.4253437 50.2244077 93.4253437 45.9594626 84.4202813 45.9594626 84.4202813 42.0924297 91.7102812 42.0924297 91.7102812 37.8274846 84.4202813 37.8274846 84.4202813 34.1229791 93.4253437 34.1229791 93.4253437 29.8524297"
            />
            <g id="Group-28" transform="translate(13.500000, 0.003307)">
              <g id="Group-10" transform="translate(0.000000, 29.142857)">
                <g id="Clip-9" />
                <path
                  d="M10.6875,16.9477484 C7.329375,16.9354187 4.617,14.2128033 4.629375,10.8669791 C4.64175,7.52115495 7.374375,4.81871538 10.7325,4.83104505 C14.0821875,4.84337473 16.790625,7.55197912 16.790625,10.8893967 C16.790625,14.2352209 14.068125,16.9477484 10.71,16.9477484 L10.6875,16.9477484 Z M10.6875,0.482034066 C4.9185,0.494363736 0.252,5.16394615 0.264375,10.9112538 C0.27675,16.6596824 4.9635,21.309089 10.7325,21.2967593 C16.4925,21.2844297 21.155625,16.6288582 21.155625,10.8893967 C21.155625,5.14152857 16.479,0.482034066 10.71,0.482034066 L10.6875,0.482034066 Z"
                  id="Fill-8"
                  fill={iconColor}
                />
              </g>
              <path
                d="M55.816875,40.2732989 L55.816875,43.2716505 L58.24125,43.2716505 L58.24125,45.5857055 C57.121875,45.9359802 55.9546875,46.1097165 54.781875,46.1013099 C51.42375,46.1102769 48.6945,43.4050352 48.6855,40.0586505 C48.6770625,36.7128264 51.3928125,33.9935736 54.7509375,33.985167 C56.3675625,33.9806835 57.918375,34.6184637 59.0625,35.7555956 L62.150625,32.6793429 C58.078125,28.6088703 51.46425,28.5976615 47.37825,32.6558044 C43.29225,36.7133868 43.282125,43.3030352 47.3551875,47.3740681 C49.323375,49.3417714 51.9991875,50.4430352 54.7875,50.4340681 C59.95125,50.4340681 61.7175,48.6294527 62.595,47.7327495 L62.595,40.2732989 L55.816875,40.2732989 Z"
                id="Fill-11"
                fill={iconColor}
              />
              <path
                d="M31.9949437,45.8886231 L28.3274438,45.8886231 L28.3274438,34.1810407 L31.9668188,34.1810407 C35.2068187,34.1810407 37.8336937,36.7982934 37.8336937,40.0264253 C37.8336937,43.2551176 35.2068187,45.8723703 31.9668188,45.8723703 L31.9949437,45.8886231 Z M31.9949437,29.8376341 L23.9793187,29.8376341 L23.9793187,50.2208209 L31.9949437,50.2208209 C37.6458188,50.2202604 42.2256937,45.6549198 42.2251312,40.024744 C42.2240063,34.3962495 37.6446937,29.8331505 31.9949437,29.8325901 L31.9949437,29.8376341 Z"
                id="Fill-13"
                fill={iconColor}
              />
              <polygon
                id="Fill-15"
                fill={iconColor}
                points="66.3244875 16.3032429 71.0618625 11.5837813 75.7992375 16.3032429 71.0618625 21.0232648"
              />
              <polygon
                id="Fill-17"
                fill={iconColor}
                points="71.8538625 10.7853231 76.5912375 6.06586154 81.3286125 10.7853231 76.5912375 15.5053451"
              />
              <g id="Group-21" transform="translate(77.062500, 0.000000)">
                <g id="Clip-20" />
                <polygon
                  id="Fill-19"
                  fill={iconColor}
                  points="0.33069375 5.27698681 5.06750625 0.556964835 9.80488125 5.27698681 5.06750625 9.99644835"
                />
              </g>
              <polygon
                id="Fill-22"
                fill={iconColor}
                points="77.3920687 16.2999923 82.1294437 11.5805308 86.8668187 16.2999923 82.1294437 21.0200143"
              />
              <polygon
                id="Fill-24"
                fill={iconColor}
                points="82.9193063 10.7820725 87.6566813 6.06261099 92.3934938 10.7820725 87.6566813 15.5020945"
              />
              <polygon
                id="Fill-26"
                fill={iconColor}
                points="88.4597063 16.3024582 93.1970812 11.5824363 97.9344562 16.3024582 93.1970812 21.0219198"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

LodgeLinkLogoWhite.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  backgroundColor: PropTypes.string,
  iconColor: PropTypes.string
};

LodgeLinkLogoWhite.defaultProps = {
  width: 162,
  height: 51,
  backgroundColor: 'transparent',
  iconColor: logoWhite
};

export default LodgeLinkLogoWhite;
