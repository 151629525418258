import { colorGrey05 } from '../../layout/css/colors';

export const DashboardHeaderStyle = {
  '& > :not(style)': { m: 1, p: 1, maxWidth: '30ch' },
  height: '40px'
};

export const DashboardStyle = {
  '& > :not(style)': { margin: 1 },
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  padding: '20px 28px'
};

export const DashboardCard = {
  display: 'flex',
  alignItems: 'center',
  background: colorGrey05,
  width: '23.5%',
  justifyContent: 'space-evenly',
  padding: '15px 0',
  borderRadius: '5px'
};
