import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './sections/login/Login.page';
import Dashboard from './sections/dashboard/Dashboard.page';
import Authenticate from './sections/authenticate/Authenticate.page';
import Layout from './layout/Layout';
import { RequireAuth } from './hoc/requireAuth/RequireAuth';
import NotFound from './sections/notFound/NotFound.page';
import { StateProvider } from './context/state.context';
import { TimerProvider } from './context/timer.context';
import { UserTokenProvider } from './context/userToken.context';


const App = () => (
  <BrowserRouter>
    <Routes>
      <Route 
        path="/authenticate/:token" 
        element={
          <UserTokenProvider>
            <Layout component={ <Authenticate /> } />
          </UserTokenProvider>
        } 
      />
      <Route 
        path="/" 
        element={
          <UserTokenProvider>
            <Layout component={ <Authenticate /> } />
          </UserTokenProvider>
        } 
      />
      <Route path="/login" element={<Layout component={<Login />} />} />

      <Route
        path="/dashboard"
        element={
          <UserTokenProvider>
            <RequireAuth>
              <StateProvider>
                <TimerProvider>
                  <Layout authed component={<Dashboard />} />
                </TimerProvider>
              </StateProvider>
            </RequireAuth>
          </UserTokenProvider>
        }
      />
      <Route
        path="*"
        element={
          <UserTokenProvider>
            <RequireAuth>
              <StateProvider>
                <Layout
                  authed
                  component={<NotFound />}
                  isNotFound
                />
              </StateProvider>
            </RequireAuth>
          </UserTokenProvider>
        }
      />
    </Routes>
  </BrowserRouter>
);

export default App;
