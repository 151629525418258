import gql from 'graphql-tag';

export const QUERY_GET_VCC_DETAILS = gql`
  query PartnerGetVCCDetails($token: String!, $bookingNumber: String!) {
    PartnerHubQueries {
      partnerGetVCCDetails(token: $token, bookingNumber: $bookingNumber) {
        __typename
        guestName
        customerName
        bookingNumber
        confirmationNumber
        duration
        cardholderName
        cardNumber
        expiry
        cvv
        incidentals
        provider
        currency
      }
    }
  }
`;
