import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useFormik } from 'formik';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { colorBurgundy, colorWhite } from '../../layout/css/colors';
import {
  ControlBoxStyle,
  InputBoxStyle,
  SearchBodyStyle,
  SearchButtonsContainerStyle,
  SearchHeaderStyle,
  StyledButton,
  StyledH1
} from './AdvancedSearch.styles';
import GraphAutoComplete from '../graphAutoComplete/GraphAutoComplete';
import {
  SEARCH_BOOKINGS,
  SEARCH_BOOKING_GUEST,
  SEARCH_CONFIRMATION_NUMBER,
  SEARCH_CUSTOMERS
} from './AdvancedSearch.gql';
import Transition from '../../layout/animation/Transition';

const returnSearchInput = (values) => {
  let bookingId;
  if (values.confirmationNumber && values.confirmationNumber.label) bookingId = values.confirmationNumber.id;
  else if (values.bookingNumber && values.bookingNumber.label) bookingId = values.bookingNumber.id;
  else bookingId = 0;

  const guestName = values.guestName ? values.guestName.label : '';
  const organizationId = values.customerName ? values.customerName.id : 0;
  const checkin = values.checkin ? values.checkin.format('YYYY-MM-DD') : null;
  const createdAt = values.createdAt ? values.createdAt.format('YYYY-MM-DD') : null;
  const todayOnly = values.todayOnly ? values.todayOnly : false;

  return {
    guestName,
    organizationId,
    bookingId,
    checkin,
    createdAt,
    todayOnly
  };
};

const AdvancedSearch = (props) => {
  const { initialBookingNumber, setInitialBookingNumber, searchInput, handleSetState, pagination } = props;

  const formik = useFormik({
    initialValues: {
      guestName: {
        id: 0,
        label: ''
      },
      customerName: {
        id: 0,
        label: ''
      },
      bookingNumber: {
        id: 0,
        label: ''
      },
      confirmationNumber: {
        id: 0,
        label: ''
      },
      checkin: null,
      createdAt: null,
      todayOnly: false
    },
    onSubmit: (values) => {
      handleSetState({
        pagination: {
          ...pagination,
          page: 0
        },
        searchInput: {
          ...searchInput,
          ...returnSearchInput(values),
          skip: 0
        }
      });
    },
    onReset: () => {
      handleSetState({
        pagination: {
          page: 0,
          rowsPerPage: 9
        },
        searchInput: {
          ...searchInput,
          top: 9,
          skip: 0,
          guestName: undefined,
          organizationId: 0,
          bookingId: 0,
          checkin: undefined,
          createdAt: undefined,
          todayOnly: false
        }
      });
    }
  });

  React.useEffect(() => {
    if (initialBookingNumber && formik.values.bookingNumber && formik.values.bookingNumber.label)
      handleSetState({
        searchInput: {
          ...searchInput,
          bookingId: formik.values.bookingNumber.id
        }
      });
  }, [formik.values.bookingNumber]);

  return (
    <Transition>
      <Box bgcolor={colorWhite} margin="10px" marginTop="50px">
        <Box sx={SearchHeaderStyle} height="40px" bgcolor={colorBurgundy}>
          <StyledH1 color={colorWhite} size="16px">
            Search
          </StyledH1>
        </Box>
        <Box component="form" noValidate autoComplete="off" bgcolor={colorWhite} sx={SearchBodyStyle}>
          <Box sx={InputBoxStyle}>
            <GraphAutoComplete
              value={formik.values.guestName}
              query={SEARCH_BOOKING_GUEST}
              queryString="PartnerHubQueries.partnerGuestTypeahead"
              inputName="guestName"
              label="Guest Name"
              setValue={(value) => formik.setFieldValue('guestName', value)}
              mapper={(data) =>
                data.map((result) => ({ id: result.id, label: `${result.firstName} ${result.lastName}` }))
              }
            />
            <GraphAutoComplete
              value={formik.values.customerName}
              query={SEARCH_CUSTOMERS}
              queryString="PartnerHubQueries.partnerCustomerTypeahead"
              inputName="customerName"
              label="Company Name"
              setValue={(value) => formik.setFieldValue('customerName', value)}
              mapper={(data) => data.map((result) => ({ id: result.id, label: result.name }))}
            />
            <GraphAutoComplete
              value={formik.values.bookingNumber}
              query={SEARCH_BOOKINGS}
              queryString="PartnerHubQueries.partnerBookingTypeahead"
              inputName="bookingNumber"
              label="Booking Number"
              disabled={!!formik.values.confirmationNumber && !!formik.values.confirmationNumber.label}
              setValue={(value) => formik.setFieldValue('bookingNumber', value)}
              mapper={(data) => data.map((result) => ({ id: result.id, label: result.bookingNumber }))}
              initialValue={initialBookingNumber}
            />
            <GraphAutoComplete
              value={formik.values.confirmationNumber}
              query={SEARCH_CONFIRMATION_NUMBER}
              queryString="PartnerHubQueries.partnerConfirmationNumberTypeahead"
              inputName="confirmationNumber"
              label="Confirmation Number"
              disabled={!!formik.values.bookingNumber && !!formik.values.bookingNumber.label}
              setValue={(value) => formik.setFieldValue('confirmationNumber', value)}
              mapper={(data) => data.map((result) => ({ id: result.id, label: result.confirmationNumber }))}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Checkin Date"
                format="YYYY-MM-DD"
                value={formik.values.checkin}
                disabled={!!formik.values.todayOnly}
                onChange={(date) => formik.setFieldValue('checkin', date)}
              />
              <DatePicker
                label="Created"
                format="YYYY-MM-DD"
                value={formik.values.createdAt}
                onChange={(date) => formik.setFieldValue('createdAt', date)}
              />
            </LocalizationProvider>
          </Box>
          <Box sx={ControlBoxStyle}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.todayOnly}
                  onChange={(event) => {
                    formik.setFieldValue('todayOnly', event.target.checked);
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label={`Show Today's Checkin Only`}
            />
            <Box sx={SearchButtonsContainerStyle}>
              <StyledButton
                name="clearSearch"
                onClick={() => {
                  formik.handleReset();
                  formik.resetForm();
                  setInitialBookingNumber('');
                  localStorage.removeItem('bookingNumber');
                }}
              >
                Clear Search
              </StyledButton>
              <StyledButton name="submitSearch" onClick={() => formik.handleSubmit()} primary="true">
                Submit
              </StyledButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Transition>
  );
};

AdvancedSearch.propTypes = {
  initialBookingNumber: PropTypes.string,
  setInitialBookingNumber: PropTypes.func,
  searchInput: PropTypes.shape({
    top: PropTypes.number,
    skip: PropTypes.number,
    guestName: PropTypes.string,
    organizationId: PropTypes.number,
    bookingId: PropTypes.number,
    checkin: PropTypes.string,
    createdAt: PropTypes.string,
    todayOnly: PropTypes.bool
  }),
  handleSetState: PropTypes.func,
  pagination: PropTypes.shape({ rowsPerPage: PropTypes.number, page: PropTypes.number })
};

AdvancedSearch.defaultProps = {
  initialBookingNumber: '',
  setInitialBookingNumber: () => {},
  searchInput: {
    top: 9,
    skip: 0,
    guestName: undefined,
    organizationId: 0,
    bookingId: 0,
    checkin: undefined,
    createdAt: undefined,
    todayOnly: false
  },
  handleSetState: () => {},
  pagination: { page: 0, rowsPerPage: 9 }
};

export default AdvancedSearch;
