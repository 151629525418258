import React, { useReducer, useEffect, useContext, useState } from 'react';
import { Box } from '@mui/material';
import get from 'lodash.get';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DateTime } from 'luxon';
import { useLazyQuery, useMutation } from '@apollo/client';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useAppState } from '../../hooks/useAppState';
import AdvancedSearchCrewRegistration from '../../buildingBlocks/crewRegistration/advancedSearch/AdvancedSearch';
import reducer from '../../reducer/state.reducer';
import { CrewRegistrationApolloClientContext } from '../../context/crewRegistrationApolloClient';
import { VIEWS } from '../../buildingBlocks/enum';
import Table from '../../buildingBlocks/Table/Table';
import { useUserToken } from '../../hooks/useUserToken';
import { CrewRegistrationNavStyle, CrewRegistrationViewStyle, StyledH1 } from './CrewRegistration.style';
import { colorBlack } from '../../layout/css/colors';
import Transition from '../../layout/animation/Transition';
import {
  QUERY_CREW_MEMBER_REGISTRATION,
  QUERY_CREW_MEMBER_REGISTRATION_BY_REFERENCE_NUMBER,
  MUTATION_GENERATE_TOKEN_CREW_REGISTRATION
} from '../../buildingBlocks/Table/Table.gql';
import CopyContent from '../../buildingBlocks/copyClipboard';

const initialState = {
  tableData: [],
  searchSubmit: true,
  error: false,
  loading: true,
  totalCount: 0,
  crewRegistrationToken: null,
  searchInput: {
    top: 9,
    skip: 0,
    searchText: null,
    startDate: DateTime.now().toUTC().minus({ days: 1 }).toFormat('yyyy-MM-dd'),
    endDate: DateTime.now().toUTC().toFormat('yyyy-MM-dd')
  },
  pagination: {
    page: 0,
    rowsPerPage: 9
  }
};
const CrewRegistrationDetails = () => {
  const { setAppState } = useAppState();
  const crewRegistrationClient = useContext(CrewRegistrationApolloClientContext);
  const [crewToken, setCrewToken] = useState(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { token: userToken } = useUserToken();
  const { searchInput, pagination, tableData, searchSubmit, totalCount, loading: loadingData } = state;
  const propertyUuid = localStorage.getItem('propertyUuid');
  const propertyName = localStorage.getItem('propertyName');
  const propertyTimeZone = localStorage.getItem('propertyTimeZone');
  const formattedTimezone = propertyTimeZone?.replace('/', '-');
  const signInSheetFormLink = `${process.env.REACT_APP_LODGELINK_CUSTOMER_URI}/crew-registration-form/${propertyName}/${propertyUuid}/${formattedTimezone}`;

  const handleSetState = (newValue) => {
    dispatch({
      type: 'SET_STATE',
      newValue
    });
  };
  const columns = [
    { id: 'fullName', label: 'Full Name', minWidth: 170 },
    { id: 'enteredName', label: 'Entered Name', minWidth: 170 },
    { id: 'pinNumber', label: 'Pin Number', minWidth: 170 },
    {
      id: 'roomNumber',
      label: 'Room Number',
      minWidth: 170
    },
    {
      id: 'dateTime',
      label: 'Date',
      minWidth: 170
    },
    {
      id: 'propertyName',
      label: 'Property Name',
      minWidth: 170
    },
    {
      id: 'actionType',
      label: 'Action',
      minWidth: 170
    }
  ];
  const [getCrewReferenceNumbers] = useLazyQuery(QUERY_CREW_MEMBER_REGISTRATION_BY_REFERENCE_NUMBER, {
    fetchPolicy: 'cache-and-network'
  });

  const buildTableData = ({ crewRegistrationData = [], crewListData = [] }) => {
    const mergedData = crewRegistrationData?.map((crewMember) => {
      const crewListMember = crewListData?.find(
        (listMember) => Number(listMember?.referenceNumber) === crewMember?.pinNumber
      );
      const fullName =
        crewListMember?.firstName && crewListMember?.lastName
          ? `${crewListMember.firstName} ${crewListMember.lastName}`
          : 'N/A';

      let enteredName = 'N/A';
      if (crewMember?.firstName || crewMember?.lastName) {
        enteredName = `${crewMember?.firstName ?? ''} ${crewMember?.lastName ?? ''}`.trim();
      }
      return {
        ...crewMember,
        ...crewListMember,
        enteredName,
        fullName,
        emailAddress: crewMember.emailAddress || 'N/A',
        dateTime: DateTime.fromISO(crewMember.dateTime, { zone: 'utc' })
          .setZone(propertyTimeZone)
          .toFormat('yyyy-MM-dd hh:mm a'),
        actionType: crewMember.actionType === 'checkin' ? 'Check-in' : 'Check-out'
      };
    });
    return mergedData;
  };

  const getCrewListData = async (referenceNumbers) => {
    if (!referenceNumbers.length) return [];
    try {
      const { data: getCrewReferenceNumbersData } = await getCrewReferenceNumbers({
        variables: {
          referenceNumbers,
          skip: searchInput.skip,
          top: searchInput.top,
          token: userToken.token
        }
      });
      const {
        getCrewMemberRegistration: { submission: crewListData }
      } = getCrewReferenceNumbersData;
      return { crewListData: crewListData || [] };
    } catch (error) {
      return error;
    }
  };

  const buildCrewInformation = async (referenceNumberData) => {
    const getData = get(referenceNumberData, 'getCrewRegistrationData.crewMemberRegistration', []);
    const count = get(referenceNumberData, 'getCrewRegistrationData.totalCount', 0);
    const referenceNumbers = getData?.map((crewMember) => crewMember?.pinNumber) || [];
    const { crewListData } = await getCrewListData(referenceNumbers);
    const formatData = buildTableData({ crewRegistrationData: getData, crewListData });
    handleSetState({
      loading: false,
      totalCount: count,
      tableData: formatData,
      searchSubmit: false
    });
  };

  const [queryCrewMemberRegistration] = useLazyQuery(QUERY_CREW_MEMBER_REGISTRATION, {
    client: crewRegistrationClient,
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      buildCrewInformation(data);
    }
  });
  const [mutationGenerateTokenForCrewRegistration] = useMutation(MUTATION_GENERATE_TOKEN_CREW_REGISTRATION, {
    variables: {
      token: userToken.token
    }
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const getToken = async () => {
      if (!crewToken) {
        const data = await mutationGenerateTokenForCrewRegistration();
        const {
          data: {
            mutationGenerateTokenForCrewRegistration: { token }
          }
        } = data;
        setCrewToken(token);
      }
    };
    getToken();
  }, []);

  useEffect(() => {
    if (crewToken && searchSubmit) {
      queryCrewMemberRegistration({
        variables: {
          propertyId: propertyUuid,
          searchText: searchInput.searchText,
          startDate: DateTime.fromISO(searchInput.startDate, { zone: 'local' }).toUTC().toISO(),
          endDate: DateTime.fromISO(searchInput.endDate, { zone: 'local' }).toUTC().toISO(),
          skip: searchInput.skip,
          top: searchInput.top,
          token: crewToken
        }
      });
    }
  }, [crewToken, searchSubmit]);

  return (
    <Box sx={CrewRegistrationViewStyle}>
      <Transition>
        <Box sx={CrewRegistrationNavStyle}>
          <KeyboardArrowLeftIcon
            sx={{ color: 'black', fontSize: '28px', cursor: 'pointer' }}
            onClick={() => {
              setAppState({
                currentView: VIEWS.default
              });
            }}
          />
          <StyledH1 color={colorBlack} size="20px">
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <span style={{ fontSize: '20px' }}>Sign-In Sheet</span>
              <span style={{ fontSize: '16px', fontWeight: 'normal' }}>{` / ${'Sign-in Sheet Form Link'}`}</span>
              <CopyContent content={signInSheetFormLink} />
            </div>
          </StyledH1>
        </Box>

        <AdvancedSearchCrewRegistration
          handleSetState={handleSetState}
          searchInput={searchInput}
          tableData={tableData}
          startDate={searchInput.startDate}
          endDate={searchInput.endDate}
          pagination={pagination}
        />
        <Table
          tableColumns={columns}
          loading={loadingData}
          totalCount={totalCount}
          tableData={tableData}
          handleSetState={handleSetState}
          searchInput={searchInput}
          pagination={pagination}
        />
      </Transition>
    </Box>
  );
};

export default CrewRegistrationDetails;
