import styled from 'styled-components';

export const StyledH2 = styled.h2`
  color: ${(props) => props.color};
  font-size: ${(props) => props.size};
  font-weight: bold;
  padding: 10;
  margin: 0 0 5px 0;
`;

export const PaymentInfoStyle = {
  marginTop: '1px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '5rem'
};
