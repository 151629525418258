/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/static-property-placement */
/* eslint-disable react/prefer-stateless-function */
import PropTypes from 'prop-types';
import React from 'react';

import { trackGA4 } from '../lib/analytics';

const analytics = {
  trackGA4
};

export const withAnalyticsPropType = PropTypes.shape({
  trackGA4: PropTypes.func.isRequired
});

/**
 * A HOC that provides analytics tracking props to pages.
 */
const withAnalytics = () => (BaseComponent) =>
  class ComponentWithAnalytics extends React.Component {
    static displayName = `withAnalytics(${BaseComponent.displayName || BaseComponent.name})`;

    render() {
      return <BaseComponent {...this.props} analytics={analytics} />;
    }
  };

export default withAnalytics;
