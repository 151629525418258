import * as React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import get from 'lodash.get';
import { useQuery } from '@apollo/client';
import { AUTHENTICATE_PARTNER } from './RequireAuth.gql';
import { useUserToken } from '../../hooks/useUserToken';
import Loading from '../../sections/loading/Loading.page';

export const RequireAuth = ({ children }) => {
  const { token: userToken } = useUserToken();
  const { data, loading, error } = useQuery(AUTHENTICATE_PARTNER, {
    variables: { token: userToken.token }
  });
  const verifiedToken = get(data, 'PartnerHubQueries.authenticatePartner.token', '');
  if (loading) return <Loading />;
  if (error) return <Navigate to="/login" replace />;
  if (verifiedToken) {
    const tokenData = JSON.parse(verifiedToken);
    const { emailAddress, bookingNumber, propertyName, isDirectBill, propertyUuid, timeZone } = tokenData;
    if (emailAddress) localStorage.setItem('emailAddress', emailAddress);
    localStorage.removeItem('bookingNumber');
    localStorage.removeItem('propertyUuid');
    localStorage.removeItem('propertyTimeZone');
    if (bookingNumber) {
      localStorage.setItem('bookingNumber', bookingNumber);
    }
    if (propertyName) localStorage.setItem('propertyName', propertyName);
    if (propertyUuid) localStorage.setItem('propertyUuid', propertyUuid);
    if (timeZone) localStorage.setItem('propertyTimeZone', timeZone);
    if (isDirectBill !== undefined || isDirectBill !== null) localStorage.setItem('isDirectBill', isDirectBill);
    return children;
  }
  return <Navigate to="/login" replace />;
};

RequireAuth.propTypes = {
  children: PropTypes.shape({})
};

RequireAuth.defaultProps = {
  children: null
};
