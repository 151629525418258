import gql from 'graphql-tag';

export const QUERY_DASHBOARD_OVERVIEW = gql`
  query partnerHubDashboardStats($token: String!) {
    PartnerHubQueries {
      partnerHubDashboardStats(token: $token) {
        totalLodgelinkBookings
        pendingBookings
        checkInToday
        checkOutToday
      }
    }
  }
`;
