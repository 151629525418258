import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  Box,
  CircularProgress
} from '@mui/material';
import { useQuery } from '@apollo/client';
import moment from 'moment-timezone';
import { colorGrey05 } from '../../layout/css/colors';
import { ButtonState, LoadingStyle, StyledH1, StyledDetailsButton, TableLayoutStyle } from './BookingTable.styles';
import { QUERY_SEARCH_BOOKINGS } from './BookingTable.gql';
import { useUserToken } from '../../hooks/useUserToken';
import withAnalytics from '../../hoc/withAnalytics';
import { useAppState } from '../../hooks/useAppState';
import { useTimer } from '../../hooks/useTimer';
import { BookingTableRow } from './BookingTableRow';
import { VIEWS } from '../enum';
import VCCButton from '../vccButton/VCCButton';
import Transition from '../../layout/animation/Transition';
import { convertUTCDateToPropertyTime } from '../../lib/utils/index';

let columns = [
  { id: 'guests', label: 'Guests', minWidth: 170 },
  { id: 'confirmationNumber', label: 'Confirmation Number', minWidth: 170 },
  { id: 'customerName', label: 'Customer/Company Name', minWidth: 170 },
  {
    id: 'bookingNumber',
    label: 'Booking Number',
    minWidth: 170
  },
  {
    id: 'checkin',
    label: 'Check-In Date',
    minWidth: 170
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    minWidth: 170
  },
  {
    id: 'vcc',
    label: 'Virtual Credit Card',
    minWidth: 170
  },
  {
    id: 'bookingDetailsButton',
    label: 'Booking Details',
    minWidth: 170
  }
];

const getBookingDetailsButton = (booking, { setAppState, analytics }) => (
  <StyledDetailsButton
    name={`bookingDetailsButton-${booking.bookingNumber}`}
    state={ButtonState.default}
    onClick={() => {
      setAppState({ currentView: VIEWS.bookingDetails, bookingDetailsOpen: booking });
      analytics.trackGA4('track-event', {
        eventName: 'clickDetails',
        sectionName: 'Bookings Table',
        bookingNumber: booking.bookingNumber
      });
    }}
  >
    Details
  </StyledDetailsButton>
);

const getBookingsData = (data) => {
  const bookingCount = get(data, 'PartnerHubQueries.partnerSearchBookings.bookingCount', 0);
  const bookings = get(data, 'PartnerHubQueries.partnerSearchBookings.bookings', []);
  return {
    bookingCount,
    bookings
  };
};

const BookingTable = (props) => {
  const { isDirectBill, analytics, searchInput, pagination, handleSetState } = props;
  const { page, rowsPerPage } = pagination;
  const { appState, setAppState } = useAppState();
  const { resetBookingTimer } = useTimer();
  const { otpDetailsMap } = appState;

  if (isDirectBill === 'true') columns = columns.filter((column) => column.id !== 'vcc');

  const { token: userToken } = useUserToken();
  const { data, loading, error } = useQuery(QUERY_SEARCH_BOOKINGS, {
    variables: { token: userToken.token, searchInput },
    fetchPolicy: 'cache-and-network'
  });
  const { bookingCount, bookings } = getBookingsData(data);

  const handleChangePage = (_, newPage) => {
    handleSetState({
      searchInput: {
        ...searchInput,
        skip: newPage * rowsPerPage
      },
      pagination: {
        ...pagination,
        page: newPage
      }
    });
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    handleSetState({
      searchInput: {
        ...searchInput,
        top: newRowsPerPage
      },
      pagination: {
        page: 0,
        rowsPerPage: newRowsPerPage
      }
    });
  };

  const formattedBookingsData = () => {
    const formattedBookings = bookings.map((booking) => {
      let checkin;
      let checkout;
      let createdAt;
      if (booking.hasTimeZoneFlag) {
        checkin = convertUTCDateToPropertyTime(booking.checkin, booking.propertyTimeZone);
        checkout = convertUTCDateToPropertyTime(booking.checkout, booking.propertyTimeZone);
        createdAt = convertUTCDateToPropertyTime(booking.createdAt, booking.propertyTimeZone);
      } else {
        checkin = moment(booking.checkin).utc().format('YYYY-MM-DD');
        checkout = moment(booking.checkout).utc().format('YYYY-MM-DD');
        createdAt = moment(booking.createdAt).utc().format('YYYY-MM-DD');
      }

      return {
        id: booking.id,
        guestsCount: booking.guests.length,
        guests: booking.guests,
        confirmationNumber: booking.confirmationNumber.length ? booking.confirmationNumber : `N/A`,
        customerName: booking.customerName,
        bookingNumber: booking.bookingNumber,
        checkin,
        checkout,
        createdAt,
        vcc: (
          <VCCButton
            bookingNumber={booking.bookingNumber}
            otpDetailsMap={otpDetailsMap}
            resetBookingTimer={resetBookingTimer}
            setState={handleSetState}
            analytics={analytics}
            hasVCC={booking.hasVCC}
          />
        ),
        bookingDetailsButton: getBookingDetailsButton(booking, { setAppState, analytics }),
        hasVCC: booking.hasVCC
      };
    });
    return formattedBookings;
  };

  const renderTable = () => {
    const formattedBookings = formattedBookingsData();
    if (error)
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', m: 2, width: '100%' }}>No Bookings/Records Found</Box>
      );
    if (loading)
      return (
        <Box sx={LoadingStyle}>
          <CircularProgress />
        </Box>
      );
    return (
      <TableContainer>
        <Transition>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={`column-${column.id}`} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {formattedBookings.map((booking) => (
                <BookingTableRow
                  key={`${booking.id}`}
                  booking={booking}
                  isDirectBill={isDirectBill}
                  searchedGuest={searchInput.guestName}
                />
              ))}
            </TableBody>
          </Table>
        </Transition>
      </TableContainer>
    );
  };

  return (
    <Box margin="10px" marginTop="20px">
      <Box
        bgcolor={colorGrey05}
        height="40px"
        sx={{
          '& > :not(style)': { m: 1, p: 1 }
        }}
      >
        <StyledH1 size="16px">Results</StyledH1>
      </Box>
      <Paper sx={TableLayoutStyle}>
        {renderTable()}
        <TablePagination
          rowsPerPageOptions={[9, 18, 27]}
          component="div"
          count={bookingCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

BookingTable.propTypes = {
  isDirectBill: PropTypes.string,
  analytics: PropTypes.shape({}),
  searchInput: PropTypes.shape({ guestName: PropTypes.string }),
  pagination: PropTypes.shape({ page: PropTypes.number, rowsPerPage: PropTypes.number }),
  handleSetState: PropTypes.func
};

BookingTable.defaultProps = {
  isDirectBill: '',
  analytics: {},
  searchInput: { guestName: '' },
  pagination: {
    rowsPerPage: 9,
    page: 0
  },
  handleSetState: () => {}
};

export default withAnalytics()(BookingTable);
