import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { TableRow, TableCell, Collapse, Alert, Link } from '@mui/material';
import { colorGrey05, logoColor3 } from '../../layout/css/colors';
import GuestListByRoom from '../scheduleDetails/guestListByRoom/GuestListByRoom';

export const BookingTableRow = ({ booking, isDirectBill, searchedGuest }) => {
  const [open, setOpen] = useState(false);
  const { confirmationNumber, customerName, bookingNumber, checkin, createdAt, bookingDetailsButton, hasVCC } =
    booking ?? {};
  const showVCCNotFound = !hasVCC && isDirectBill === 'false';
  return (
    <>
      <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }} bgcolor={open ? colorGrey05 : 'transparent'}>
        <TableCell
          onClick={() => setOpen(!open)}
          style={{ color: logoColor3, cursor: 'pointer', textDecoration: 'underline' }}
        >{`${booking.guestsCount} Guest(s)`}</TableCell>

        <TableCell align="left">{confirmationNumber}</TableCell>
        <TableCell scope="row"><span className="notranslate">{customerName}</span></TableCell>
        <TableCell align="left">{bookingNumber}</TableCell>
        <TableCell align="left">{checkin}</TableCell>
        <TableCell align="left">{createdAt}</TableCell>
        {isDirectBill === 'false' && <TableCell align="left">{booking.vcc}</TableCell>}
        <TableCell align="left">{bookingDetailsButton}</TableCell>
      </TableRow>

      { showVCCNotFound && (
        <TableRow>
          <TableCell sx={{ p: 0, m: 0 }} colSpan={8}>
            <Alert severity="warning" sx={{ fontSize: '10px', '& .MuiAlert-icon': { fontSize: '15px' } }}>
              VCC is unavailable for this booking. Please Contact LodgeLink at&nbsp; 
              <Link color='secondary' underline="hover" href="tel:+1-844-590-5460">1-844-590-5460
              </Link>
            </Alert>
          </TableCell>
        </TableRow>
      )}

      <TableRow>
        <TableCell sx={{ padding: '0 20px' }} colSpan={8}>
          <Collapse in={open} timeout="auto">
            <GuestListByRoom bookingGuests={booking.guests} searchedGuest={searchedGuest} color={colorGrey05} />
            <Alert severity="warning" sx={{ marginBottom: '20px' }}>
              Please note that this list contains all the guests within this booking from {booking.checkin} –{' '}
              {booking.checkout}
            </Alert>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

BookingTableRow.propTypes = {
  booking: PropTypes.shape({
    id: PropTypes.number,
    guestsCount: PropTypes.number,
    guests: PropTypes.arrayOf(
      PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        roomIndex: PropTypes.number
      })
    ),
    confirmationNumber: PropTypes.string,
    customerName: PropTypes.string,
    bookingNumber: PropTypes.string,
    checkin: PropTypes.string,
    checkout: PropTypes.string,
    createdAt: PropTypes.string,
    vcc: PropTypes.element
  }),
  searchedGuest: PropTypes.string,
  isDirectBill: PropTypes.string
};

BookingTableRow.defaultProps = {
  booking: {},
  searchedGuest: '',
  isDirectBill: ''
};
