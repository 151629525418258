import React from 'react';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

const MutedTextField = (props) => {
  const { label, value, name } = props;
  return (
    <TextField
      name={name}
      label={label}
      variant="standard"
      value={value}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        readOnly: true
      }}
      focused={false}
    />
  );
};

MutedTextField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

export default MutedTextField;
