import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import get from 'lodash.get';
import { Box, CircularProgress, Typography } from '@mui/material';
import { QUERY_ROOM_SCHEDULE } from './IndividualGuestSchedule.gql';
import RoomScheduleCard from './roomScheduleCard/RoomScheduleCard';
import { useUserToken } from '../../../hooks/useUserToken';
import { ErrorStyle, LoadingStyle } from './IndividualGuestSchedule.style';
import { stripError } from '../../../lib/utils';
import { colorGrey04 } from '../../../layout/css/colors';
import Transition from '../../../layout/animation/Transition';

const getRoomScheduleData = (data) => {
  const roomSchedule = get(data, 'PartnerHubQueries.partnerGetBookingRoomSchedule.roomIndexSummaries', []);
  return roomSchedule;
};

const getInboundRate = (data) => {
  const inboundRate = get(data, 'PartnerHubQueries.partnerGetBookingRoomSchedule.inboundRate', {});
  return inboundRate;
};

const IndividualGuestSchedule = (props) => {
  const { bookingId } = props;
  const { token: userToken } = useUserToken();
  const { data, loading, error } = useQuery(QUERY_ROOM_SCHEDULE, {
    variables: { token: userToken.token, bookingId },
    fetchPolicy: 'cache-and-network'
  });
  if (error)
    return (
      <Box sx={ErrorStyle} border={1} borderColor={colorGrey04}>
        {stripError(error)}
      </Box>
    );
  if (loading)
    return (
      <Box sx={LoadingStyle}>
        <CircularProgress sx={{ m: 1 }} />
      </Box>
    );
  return (
    <Box>
      <Transition>
        {getRoomScheduleData(data).map((room, roomNumber) => (
          <RoomScheduleCard key={room.roomIndex} room={room} roomNumber={roomNumber + 1} />
        ))}
      </Transition>
      <Transition>
        <Typography variant="h7" fontWeight="bold">{`Booking Total (Not inclusive of Taxes and Fees): ${
          getInboundRate(data).formatted
        }`}</Typography>
      </Transition>
    </Box>
  );
};

IndividualGuestSchedule.propTypes = {
  bookingId: PropTypes.number
};

IndividualGuestSchedule.defaultProps = {
  bookingId: 0
};

export default IndividualGuestSchedule;
